<template>
    <div class="dropdown-filter" @blur="close()" tabindex="0">
        <div class="selected" :class="{ open: open, 'placeholder': !selected['value'] }" @click="open = !open">
            <p>{{ selected["title"] }}</p>
        </div>

        <div class="items" :class="{ selectHide: !open }">
            <div
                v-for="(option, i) of options"
                :key="i"
                @click="
                    open = false;
                    input(option);
                "
                :class="{ 'selected-item': option['value'] === selected['value'] }"
            >
                <p>{{ option["title"] }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "ResultOrderDropdown",
    props: {
        options :{
            type: Array,
            required: true,
        },
        default: {
            type: Object,
            required: false,
            default: null,
        },
    },
    setup(props) {
        const store = useStore();

        const selected = reactive(props.default
                        ? { ...props.default }
                        : props.options.length > 0
                        ? { ...props.options[0] }
                        : { value: "relevance", title: "Relevance" });
        const open = ref(false);

        function close() {
            open.value = false;
        }
        
        function input(option) {
            if (selected["value"] == option["value"] && selected["title"] == option["title"]) {
                return;
            }

            selected["value"] = option["value"];
            selected["title"] = option["title"];

            store.commit("search/setProperty", { property: "order", newProperty: selected["value"] });
        }

        input(selected);

        return {
            selected,
            open,
            input,
            close,
        }
    },
}
</script>

<style scoped>
    .dropdown-filter {
        position: relative;
        z-index: 99;

        text-align: left;
        outline: none;

        font-size: 13.25px;
        line-height: 47px;

        width: 100%;
        max-width: 200px;
    }

    .selected {
        display: flex;
        align-items: center;
        height: 30px;
        
        padding-left: 0.5rem;

        border-radius: 6px; /* 5px */
        border: 1px solid var(--highlight-hover-color);

        color: var(--main-text-color);
        background-color: var(--highlight-color);

        cursor: pointer;
        user-select: none;

        transition: 0.2s ease-out;
    }

    .selected.open {
        border: 1px solid var(--highlight-hover-color);
        border-radius: 6px 6px 0px 0px;
    }

    .selected:after {
        position: absolute;

        width: 0;
        height: 0;

        top: 13px;
        right: 1em;
        
        content: "";

        border: 5px solid transparent;
        border-color: var(--dropdown-arrow-color) transparent transparent transparent;

        transition: 0.2s ease-out;
    }

    .items {
        position: absolute;
        overflow: hidden;

        border-radius: 0px 0px 6px 6px;
        border-right: 1px solid var(--highlight-hover-color);
        border-left: 1px solid var(--highlight-hover-color);
        border-bottom: 1px solid var(--highlight-hover-color);

        box-shadow: 0px 0px 10px 1px var(--light-box-shadow-color);
        
        color: var(--main-text-color);
        background-color: var(--highlight-color);
        
        left: 0;
        right: 0;
        z-index: 1;

        animation: fadeIn 0.2s ease-out;
        transition: 0.2s ease-out;
    }

    .items div {
        display: flex;
        align-items: center;

        height: 30px;
        padding-left: 0.5rem;

        color: var(--main-text-color);

        cursor: pointer;
        user-select: none;

        transition: 0.2s ease-out;
    }

    .items div:hover {
        background-color: var(--highlight-hover-color);
    }

    .selected-item {
        background-color: var(--highlight-hover-color);
    }

    .selected-item p {
        color: rgb(117, 117, 117);
    }

    .selectHide {
        display: none;
    }

    .placeholder {
        color: rgb(117, 117, 117);
    }

    @keyframes fadeIn {
        0% { 
            opacity: 0;
        }

        100% {
            opacity: 1;
            }
    }
</style>
