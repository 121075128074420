<template>
    <div class="json-table">
        <table>
            <tr v-for="key in Object.keys(jsonData)" :key="key">
                <td class="json-key">{{ key }}</td>
                
                <td class="json-value no-data-indicator" v-if="!jsonData[key] || jsonData[key] === '-'">No Data</td>

                <td class="json-value" v-else-if="Array.isArray(jsonData[key])">
                    <ul class="table-value-list">
                        <li v-for="value in jsonData[key].filter(value => value)" :key="value">{{ value }}</li>
                    </ul>
                </td>

                <td class="json-value" v-else-if="jsonData[key].constructor !== Object">
                    {{ jsonData[key] }}
                </td>

                <td class="json-value" v-else-if="jsonData[key].constructor == Object && ('Code' in jsonData[key])">
                    {{ jsonData[key]["Code"] }}
                </td>

                <div
                    class="json-value content-hider-div"
                    v-if="jsonData[key] && jsonData[key].constructor == Object"
                >
                    <content-hider>
                        <JSONTable :jsonData="jsonData[key]" />
                    </content-hider>
                </div>
            </tr>
        </table>
    </div>
</template>

<script>
import ContentHider from './ContentHider.vue';

export default {
    name: "JSONTable",
    components: {
        ContentHider,
    },
    props: {
        jsonData: {
            type: Object,
            required: true,
        },
        hasExpandableContent: {
            type: Boolean,
            required: false,
        },
    },
}
</script>

<style scoped>
    table {
        width: 100%;;
        border-collapse: collapse;
    }

    tr {
        display: flex;

        padding: 8px 0;
        border-bottom: thin solid var(--secondary-border-color);
    }

    td {
        max-width: 100%;
        word-wrap: break-word;
    }

    .json-table {
        /* overflow: auto; Used to be used to create scroll, but doesn't work with the content hider */
        overflow: visible;

        background-repeat: no-repeat;
        background-attachment: local, local, scroll, scroll;
    }

    .json-key {
        font-weight: bold;
        margin: 0 50px 0 5px;
        text-align: left;
    }

    .json-value {
        margin-left: auto;
        text-align: right;
    }

    .table-value-list {
        margin: 0 0 0 20px;
        padding: 0;
        text-align: left;
    }

    .table-value-list li {
        overflow-wrap: break-word;
    }

    .content-hider-div {
        display: flex;
        align-items: center;

        width: 17px;
        margin: auto 0 auto 4px;
    }

    .no-data-indicator {
        font-weight: bold;
        color: var(--error-message-color);
    }
   
    @media only screen and (max-width: 435px) {
        tr {
            flex-direction: column;
        }

        .json-value {
            display: flex;

            text-align: left;
            margin-left: 5px;
            margin-right: auto;
        }
    }
</style>