<template>
    <div class="data-table">
        <div class="header">
            <h4 class="table-title"  v-if="!titleLink || titleLink === '#'">
                {{ title }}
            </h4>

            <h4 class="table-title" v-else>
                <a
                    class="header-link"
                    :href="titleLink"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {{ title }}
                </a>
            </h4>
            
            <!-- <input class="refresh-button" type="button" @click="refreshData('celestrak')" /> -->
        </div>

        <!-- For any individualised extras between the title and table -->
        <slot></slot>

        <div class="scrollable-div">
            <JSONTable
                class="json-table"
                v-if="Object.keys(jsonData).length !== 0"
                :jsonData="jsonData"
                :hasExpandableContent="hasExpandableContent"
            />

            <h3 v-else class="error no-table-indicator">No Data Available</h3>
        </div>
    </div>    
</template>

<script>
import JSONTable from './JSONTable.vue';

export default {
    name: "DataTable",
    components: {
        JSONTable,
    },
    props: {
        title: {
            type: String,
            required: false,
        },
        titleLink: {
            type: String,
            required: false,
        },
        jsonData: {
            type: Object,
            required: true,
        },
        hasExpandableContent: {
            type: Boolean,
            required: false,
        },
    },
}
</script>

<style scoped>
    .data-table {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
    }

    .data-table h4 {
        margin: 0 auto;
        transition: 0.2s ease-out;
    }

    .table-title {
        margin: auto;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        color: var(--main-text-color);

        background-color: inherit;
    }

    .header-link {
        text-decoration: none;
        color: var(--main-text-color);

        transition: 0.2s ease-out;
    }

    .header-link:hover {
        text-decoration: underline;
    }

    .scrollable-div {
        width: 100%;
    }

    .json-table {
        margin-top: 6px;
        width: 100%;

        background-color: inherit;
    }

    .error {
        color: var(--error-message-color);
    }

    .no-table-indicator {
        margin: 10px auto;
    }

    .refresh-button {
        width: 1%;
        height: 100%;
        margin: 0 0 0 auto;

        border: none;
        outline: none;
        background-color: transparent;

        background-image: url('../../assets/refresh_icon.png');
        background-repeat: no-repeat;
        background-size: contain;

        -webkit-filter: var(--webkit-image-inverter);
        filter: var(--image-inverter);

        transition: 0.08s ease-out;

        border-radius: 6px 0;
    }

    .refresh-button:hover {
        box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
        -webkit-box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
        -moz-box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
        cursor: pointer;
    }

    .refresh-button:active {
        box-shadow: 0px 0px 5px 1px var(--medium-box-shadow-color);
        -webkit-box-shadow: 0px 0px 5px 1px var(--medium-box-shadow-color);
        -moz-box-shadow: 0px 0px 5px 1px var(--medium-box-shadow-color);
    }
</style>
