<template>
    <div>
        <h2>Sign up</h2>
    </div>
</template>

<script>
export default {
    name: "Signup",
    setup() {
        
    },
}
</script>

<style scoped>

</style>
