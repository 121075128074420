<template>
  <div id="themed-content" :data-theme="theme">
    <navbar />

    <div class="content">
      <router-view :key="$route.path" />
    </div>

    <Footer />
    <!-- <cookie-consent-banner /> -->
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

import Navbar from './components/common/Navbar.vue';
import Footer from './components/common/Footer.vue';
// import Cookies from 'js-cookie';
// import CookieConsentBanner from './components/common/CookieConsentBanner.vue';

export default {
    name: "App",
    components: {
        Navbar, 
        Footer,
        // CookieConsentBanner,
    },
    setup() {
        const store = useStore();

        store.dispatch("satellite_data/getTranslationData");

        return {
            theme: computed(() => store.state.global.theme),
        }
    },
}
</script>

<style>
    * {
        box-sizing: border-box;
        image-rendering: -webkit-optimize-contrast;
    }

    #themed-content[data-theme="light"] {
        transition: 0.2s ease-out;

        --main-page-color: white;
        
        --inverted-color: black;
        --inverted-color-soft: rgba(0, 0, 0, 0.85);

        --highlight-color: rgb(248, 248, 248);
        --highlight-hover-color: rgb(235, 235, 235);

        /* --warning-color: rgba(216, 46, 46, 0.664); */
        --warning-color: rgba(252, 137, 137, 0.39);
        --warning-hover-color: rgba(216, 46, 46, 0.411);

        --main-text-color: #2c3e50;
        --secondary-text-color: #848484;
        --tertiary-text-color: #373737;
        --quaternary-text-color: #2D2D2D;

        --inverted-text-color: white;
        --error-message-color: red;

        --main-border-color: none;
        --secondary-border-color: rgba(210, 210, 210, 0.5);

        --searchbar-color: white;
        --searchbar-placeholder-color: lightgray;

        --tab-active-color: rgb(9, 133, 227);
        --tab-inactive-color: #ddd;
        --tab-hover-color: rgba(173, 173, 173, 0.65);
        --tab-text-color: var(--inverted-text-color);

        --toggle-color: var(--tab-active-color);

        --dropdown-arrow-color: rgba(173, 173, 173, 0.65);

        --button-background-hover-color: rgba(212, 212, 212, 0.5);

        --navbar-color: white;

        --footer-color: rgba(211, 211, 211, 0.325);
        --footer-link-color: rgb(136, 136, 136);

        --skeleton-accent-primary-color: #ddd;
        --skeleton-accent-secondary-color: rgb(230, 230, 230, 0.85);

        --skeleton-background: linear-gradient(90deg, transparent 0%, var(--skeleton-accent-secondary-color) 45%, var(--skeleton-accent-secondary-color) 55%, transparent 100%);

        --scrollbar-main-color: rgba(125, 125, 125, 0.5);
        --scrollbar-active-color: rgba(125, 125, 125, 0.75);

        --light-box-shadow-color: rgba(0, 0, 0, 0.065);
        --medium-box-shadow-color: rgba(51, 50, 50, 0.247);
        --dark-box-shadow-color: rgba(0, 0, 0, 0.7);

        --webkit-image-inverter: invert(0%); /* Safari/Chrome */
        --image-inverter: invert(0%);
        --image-opacity: 1;
    }

    #themed-content[data-theme="dark"] {
        transition: 0.2s ease-out;
        
        --main-page-color: #121212;

        --inverted-color: white;
        --inverted-color-soft: rgba(255, 255, 255, 0.85);

        --highlight-color: #242424;
        --highlight-hover-color: #323232;

        --warning-color: rgba(221, 128, 128, 0.692);
        --warning-hover-color: rgba(216, 46, 46, 0.664);

        --main-text-color: rgba(255, 255, 255, 0.85);
        --secondary-text-color: #8D8D93;
        --tertiary-text-color: #47474A;
        --quaternary-text-color: #2A2A2C;

        --inverted-text-color: #2c3e50;
        --error-message-color: rgba(255, 0, 0, 0.75);

        --main-border-color: #4D4D4D;
        --secondary-border-color: rgba(210, 210, 210, 0.35);

        --searchbar-color: rgba(75, 75, 75, 0.5);
        --searchbar-placeholder-color: rgba(255, 255, 255, 0.25);

        --tab-active-color: rgba(9, 133, 227, 0.75);
        --tab-inactive-color: #3C3C3C;
        --tab-hover-color: #484848;
        --tab-text-color: var(--main-text-color);

        --toggle-color: rgb(85, 85, 85);

        --dropdown-arrow-color: #484848;

        --button-background-hover-color: rgba(88, 88, 88, 0.5);

        --navbar-color: rgba(59, 59, 59, 0.325);

        --footer-color: rgba(59, 59, 59, 0.325);
        --footer-link-color: rgba(185, 185, 185, 0.75);

        --skeleton-accent-primary-color: #3C3C3C;
        --skeleton-accent-secondary-color: #444444;

        --skeleton-background: linear-gradient(90deg, transparent 0%, var(--skeleton-accent-secondary-color) 45%, var(--skeleton-accent-secondary-color) 55%, transparent 100%);

        --scrollbar-main-color: rgba(50, 50, 50, 0.5);
        --scrollbar-active-color: rgba(50, 50, 50, 0.75);

        --light-box-shadow-color: rgba(0, 0, 0, 0.05);
        --medium-box-shadow-color: rgba(51, 50, 50, 0.247);
        --dark-box-shadow-color: rgba(0,0,0,0.7);

        --webkit-image-inverter: invert(95%); /* Safari/Chrome */
        --image-inverter: invert(95%);
        --image-opacity: 0.75;
    }

    html, body {
        min-height: 100%;
        height: 100%;
        margin: 0;
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;

        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--scrollbar-main-color);
    }

    ::-webkit-scrollbar-thumb:active {
        background: var(--scrollbar-active-color);
    }

    .content {
        flex: 1 0 auto;

        /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
        padding: 20px;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        display: flex;
        flex-direction: column;
        text-align: center;

        height: 100%;
        min-height: 100%;

        color: var(--main-text-color);
        background-color: var(--main-page-color);
    }

    #themed-content {
        display: flex;
        flex-direction: column;
        height: 100%;

        text-align: center;
        color: var(--main-text-color);
        background-color: var(--main-page-color);
        min-height: 100%;

        overflow-y: scroll;
        overflow-x: hidden;
    }

    #nav {
        padding: 30px;
    }

    #nav a {
        font-weight: bold;
        color: var(--main-text-color);
    }

    #nav a.router-link-exact-active {
        color: #42b983;
    }

    /* Used for the skeleton loading animation */
    @keyframes load {
            from {
                left: -150px;
            }
            to {
                left: 100%;
            }
        }

    @media only screen and (max-width: 394px) {
        #app {
            font-size: 14px;
        }
    }
</style>
