import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Search from '../views/Search.vue';
import AdvancedSearch from '../views/AdvancedSearch.vue';
import About from '../views/About.vue';
import WhoWeAre from '../views/WhoWeAre.vue';
import Products from '../views/Products.vue';
import API from '../views/API.vue';
import Sources from '../views/Sources.vue';
import Login from '../views/Login.vue';
import Signup from '../views/Signup.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Satellite Data Viewer',
      metaTags: [
        {
          name: 'description',
          content: 'Easily search for satellite data from multiple sources.'
        },
        {
          property: 'og:description',
          content: 'Easily search for satellite data from multiple sources.'
        }
      ]
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: 'About - Satellite Data Viewer',
      metaTags: [
        {
          name: 'description',
          content: 'Learn more about our company, products, and API.'
        },
        {
          property: 'og:description',
          content: 'Learn more about our company, products, and API.'
        }
      ]
    },
  },
  {
    path: '/about/us',
    name: 'Who We Are',
    component: WhoWeAre,
    meta: {
      title: 'Who We Are - Satellite Data Viewer',
      metaTags: [
        {
          name: 'description',
          content: 'Learn more about our company.'
        },
        {
          property: 'og:description',
          content: 'Learn more about our company.'
        }
      ]
    },
  },
  {
    path: '/about/products',
    name: 'Products',
    component: Products,
    meta: {
      title: 'Products - Satellite Data Viewer',
      metaTags: [
        {
          name: 'description',
          content: 'Learn more about our products'
        },
        {
          property: 'og:description',
          content: 'Learn more about our products.'
        }
      ]
    },
  },
  {
    path: '/about/api',
    name: 'API',
    component: API,
    meta: {
      title: 'API - Satellite Data Viewer',
      metaTags: [
        {
          name: 'description',
          content: 'Learn more about our API.'
        },
        {
          property: 'og:description',
          content: 'Learn more about our API.'
        }
      ]
    },
  },
  {
    path: '/sources',
    name: 'Sources',
    component: Sources,
    meta: {
      title: 'Sources - Satellite Data Viewer',
      metaTags: [
        {
          name: 'description',
          content: 'Thank you to all of the sources we make use of.'
        },
        {
          property: 'og:description',
          content: 'Thank you to all of the sources we make use of.'
        }
      ]
    },
  },
  {
    path: '/satellite/:ncn',
    name: 'Satellite',
    component: () => import('../views/Satellite.vue'),
    meta: {
      title: 'Satellite Data Viewer',
      metaTags: [
        {
          name: 'description',
          content: 'View satellite information.'
        },
        {
          name: 'og:site_name',
          content: 'Satellite Data Viewer'
        },

        // Facebook tags (needed for sharing)
        {
          name: 'og:type',
          content: 'website'
        },
        {
          name: 'og:title',
          content: 'Satellite Data'
        },
        {
          name: 'og:description',
          content: 'View satellite information.'
        },
        {
          name: 'og:image',
          // URL to InTrack logo on website (logo with text on bottom)
          content: 'https://img1.wsimg.com/isteam/ip/55c8cee5-f6cf-4ccd-a35d-e49e9b5b01cb/logo/7413ff09-f747-43bc-86a4-217d17cac0de.png/:/rs=h:400/qt=q:95'
        },

        // Twitter tags (needed for sharing)
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:site',
          content: '@intracksolutions'
        },
        {
          name: 'twitter:title',
          content: 'Satellite Data'
        },
        {
          name: 'twitter:description',
          content: 'View satellite information'
        },
        {
          name: 'twitter:image',
          content: 'https://img1.wsimg.com/isteam/ip/55c8cee5-f6cf-4ccd-a35d-e49e9b5b01cb/logo/7413ff09-f747-43bc-86a4-217d17cac0de.png/:/rs=h:400/qt=q:95'
        },
        {
          name: 'twitter:image:alt',
          content: 'The InTrack Solutions logo with the text "InTrack Solutions" underneath'
        },
      ]
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    // props: route => ({ query: route.query.q }),
    meta: {
      title: 'Search - Satellite Data Viewer',
    }
  },
  {
    path: '/advanced-search',
    name: 'Advanced Search',
    component: AdvancedSearch,
    meta: {
      title: 'Advanced Search - Satellite Data Viewer',
    }
  },
  {
    path: '/log-in',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Log in',
      metaTags: [
        {
          name: 'description',
          content: 'Log in to your account.'
        },
        {
          property: 'og:description',
          content: 'Log in to your account.'
        }
      ]
    }
  },
  {
    path: '/sign-up',
    name: 'Signup',
    component: Signup,
    meta: {
      title: 'Sign up',
      metaTags: [
        {
          name: 'description',
          content: 'Sign up for an account.'
        },
        {
          property: 'og:description',
          content: 'Sign up for an account.'
        }
      ]
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Runs before every route change, including on page load
router.beforeEach((to, from, next) => {
  // Find the nearest route with a title and meta tags
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  let routerObjectWithTitle;

  if(nearestWithTitle) {
    routerObjectWithTitle = nearestWithTitle;
  } else if(previousNearestWithMeta) {
    routerObjectWithTitle = previousNearestWithMeta;
  }

  if (routerObjectWithTitle.name === "Satellite") {
    document.title = to.params.ncn + " - Satellite Data Viewer";
    // document.title = "Satellite Data Viewer - " + to.params.ncn;
  } else {
    document.title = routerObjectWithTitle.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none
  if(!nearestWithMeta) return next();

  if (nearestWithMeta.name === "Satellite") {
    nearestWithMeta.meta.metaTags.forEach(element => {
      if (element.name.endsWith("title")) {
        element.content = to.params.ncn + " - Satellite Data Viewer";
      } else if (element.name.endsWith("description")) {
        element.content = "View information about satellite " + to.params.ncn + " (NORAD ID).";
      } else if (element.name.endsWith("url")) {
        element.content = "https://satellitedataviewer.com/satellite/" + to.params.ncn;
      } 
    });
  }

  // Turn the meta tag definitions into actual elements in the head
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // This is used to track which meta tags we create so we don't interfere with others
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  .forEach(tag => document.head.appendChild(tag)); // Add the meta tags to the document head

  next();
});

export default router
