<template>
    <button class="reset-button" type="submit" @click="resetAdvancedSearch">Reset</button>
</template>

<script>
import { useStore } from 'vuex';

export default {
    name: "ResetButton",
    setup() {
        const store = useStore();

        function resetAdvancedSearch() {
            store.commit("advanced_search/reset");
        }

        return {
            resetAdvancedSearch,
        }
    }    
}
</script>

<style scoped>
    .reset-button {
        width: 150px;
        height: 33px;

        cursor: pointer;
        border-radius: 5px;
        border-color: transparent;
        color: var(--main-text-color);
        /* background-color: var(--warning-color); */
        background-color: var(--highlight-color);

        font-weight: bold;

        transition: 0.2s ease-out;
    }

    .reset-button:hover {
        box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
        /* background-color: var(--warning-hover-color); */
        background-color: var(--highlight-hover-color);
        background-size: 22px 22px;
    }

    .reset-button:active {
        box-shadow: none;
    }
</style>