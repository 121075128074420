<template>
    <div class="quick-link">
        <h4 class="ql-title">{{ displayText }}
            <router-link :to="routerLink">
                <span class="link-spanner"></span> <!-- Makes the whole div a clickable link -->
            </router-link>
        </h4>

        <div class="separator">
            <h5 class="link-display" v-if="showLink">{{ routerLink }}</h5>
        </div>
        
        <div class="bounding-box"></div>
    </div>
</template>

<script>
export default {
    props: {
        displayText: {
            type: String,
            required: true,
        },
        routerLink: {
            type: String,
            required: true,
        },
        showLink: {
            type: Boolean,
            required: false,
        }
    },
}
</script>

<style scoped>
    .quick-link {
        max-width: 536px;
        min-width: 226px;
        width: 100%;
        height: 3em;
        
        margin-bottom: 10px;
        padding-left: 15px;

        position: relative;

        display: flex;
        align-items: center;

        font-weight: bold;
        
        background-color: var(--highlight-color);
        border-radius: 5px;

        cursor: pointer;
        transition: 0.2s ease-out;
    }

    .quick-link:hover {
        box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
        background-color: var(--highlight-hover-color);
    
        transition: 0.08s ease-out;
    }

    .quick-link:hover .bounding-box {
        transform: translateX(10px);
    }

    .ql-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .link-display {
        margin-left: auto;
        padding-right: 10px;

        color: var(--secondary-text-color);
    
        text-overflow: ellipsis;
    }

    .separator {
        margin-left: auto;
        display: flex;
    }

    .bounding-box {
        height: 35%;
        width: 10%;

        background-image: url('../../assets/arrow.png');
        background-repeat: no-repeat;
        background-size: contain;

        -webkit-filter: var(--webkit-image-inverter);
        filter: var(--image-inverter);

        transition: 0.08s ease-out;
    }

    .link-spanner {
        position: absolute;
        
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        z-index: 1;
    }

    @media only screen and (max-width: 455px) {
        .bounding-box {
            margin-right: 10px;
        }
    }

    @media only screen and (max-width: 350px) {
        #quick-link:hover .bounding-box {
            transform: translateX(0px);
        }
    }
</style>