<template>
    <div>
        <div class="info-button">
            <p class="question-mark">?</p>
        </div>

        <div class="info-box">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        
    },
}
</script>

<style scoped>
    .info-button {
        width: 22px;
        height: 22px;

        padding: auto;

        color: var(--secondary-text-color);
        border: 1px solid var(--secondary-border-color);
        border-radius: 100%;
    }

    .info-button:hover {
        cursor: help;
    }

    .question-mark {
        width: 22px;
        height: 22px;
        
        margin: 0;
        padding-right: 1.5px;

        text-align: center;
        line-height: 22px;
        font-size: 10pt;
    }

    .info-box {
        position: absolute;
        display: none;

        z-index: 1;
        bottom: 29px;
        right: -76px;

        width: 180px;
        height: fit-content;
        padding: 8px 2px 8px 8px;

        font-size: 9pt;

        opacity: 0.95;
        background-color: var(--highlight-color);

        border: 1px solid var(--secondary-border-color);
        border-radius: 5px;
        box-shadow: 0px 0px 10px 1px var(--light-box-shadow-color);
    }

    /* .info-box:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 0;
        height: 0;
        border-top: solid 10px transparent;
        border-left: solid 75px transparent;
        border-right: solid 75px transparent;
    } */

    .info-box:after {
        content: '';
        position: absolute;
        bottom: -9px; 

        width: 15px;
        height: 15px;
        margin-left: -8px;

        background: var(--highlight-color);

        border-top: none;
        border-left: none;
        border-bottom: 1px solid var(--secondary-border-color);
        border-right: 1px solid var(--secondary-border-color);
        
        transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }

    .info-button:hover + .info-box {
        display: block;
    }

    .info-box:hover {
        display: block;
    }

    @media only screen and (max-width: 740px) {
        .info-box {
            right: -150px;
        }

        .info-box:after {
            width: 14px;
            height: 14px;
            margin-left: -82px;
        }
    }

    @media only screen and (max-width: 590px) {
        .info-box {
            right: -150px;
        }

        .info-box:after {
            width: 14px;
            height: 14px;
            margin-left: -82px;
        }
    }
</style>
