<template>
    <div class="news-card">
        <div class="main-content">
            <div class="image-wrapper">
                <img class="thumbnail" :src="article.image_url">
            </div>

            <div class="main-text-content">
                <h4 class="headline">{{ article.title }}</h4>

                <p class="description secondary-text line-clamp">{{ article.summary }}</p>

                <p class="site secondary-text">{{ article.news_site }}</p>
            </div>
        </div>

        <a :href="article.url" target="_blank" rel="noopener noreferrer">
            <span class="link-spanner"></span> <!-- Makes the whole div a clickable link -->
        </a>
    </div>
</template>

<script>

export default {
    name: "NewsCard",
    props: {
        article: Object,
    },
    setup() {

        return {

        }
    }
}
</script>

<style scoped>
    .news-card {
        position: relative;

        min-width: 5rem;
        width: auto;
        max-width: 33.5rem;
        
        max-height: 175px;

        padding: 10px;

        background-color: var(--highlight-color);
        border-radius: 5px;

        cursor: pointer;
        transition: 0.2s ease-out;
    }

    .news-card:hover {
        box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
        -webkit-box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
        -moz-box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
        background-color: var(--highlight-hover-color);

        transition: 0.08s ease-out;
    }

    .link-spanner {
        position: absolute;

        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        z-index: 1;
    }

    .main-content {
        display: flex;
        max-height: 100%;
    }

    .image-wrapper {
        position: relative;
    }

    .thumbnail {
        object-fit: cover;
        width: 210px;
        height: 155px;

        border-radius: 5px;
        opacity: var(--image-opacity);
        transition: 0.2s ease-out;
    }

    .main-text-content {
        display: flex;
        flex-direction: column;
        flex: 1;

        margin: 0 0 0 10px;
        max-height: 155px;
    }

    .headline {
        text-align: left;
        margin: 0;
    }

    .secondary-text {
        margin: 5px 0 0 0;

        font-size: 0.8rem;
        text-align: left;

        color: var(--secondary-text-color);
    }

    .description {
        overflow: hidden;
        text-justify: inter-word;
    }

    .line-clamp {
      position: relative;
      max-height: 6em; /* 5 lines */
    }

    .line-clamp:after {
        content: "";
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 70%;
        height: 1.2em;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
    }

    /* Desired styling - the description is cut off by ellipses */
    @supports (-webkit-line-clamp: 5) {
        .line-clamp {
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;  
        }

        .line-clamp:after {
            display: none;
        }
    }

    .site {
        margin-top: auto;
        font-style: oblique;
    }

    @media only screen and (max-width: 500px) {
        .main-content {
            flex-direction: column;
        }

        .news-card {
            max-height: none;
        }

        .thumbnail {
            width: 100%;
        }

        .main-text-content {
            margin-left: 0;
            max-height: none;
        }

        .description {
            max-height: none;
        }
    }
</style>
