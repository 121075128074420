<template>
    <div class="date-input">
        <input
            class="input year"
            type="number"
            v-model="year"
            ref="yearInput"
            @keyup="onKeyUp('year')"
            @blur="validateDate('year')"
            @focus="selectInputContents('year')"
            min="0"
            placeholder="yyyy"
        >

        <span class="divider">-</span>

        <input
            class="input month"
            type="number"
            v-model="month"
            ref="monthInput"
            @keyup="onKeyUp('month')"
            @blur="validateDate('month')"
            @focus="selectInputContents('month')"
            min="0"
            max="12"
            placeholder="mm"
        >

        <span class="divider">-</span>

        <input
            class="input day"
            type="number"
            v-model="day"
            ref="dayInput"
            @keyup="onKeyUp('day')"
            @blur="validateDate('day')"
            @focus="selectInputContents('day')"
            min="0"
            max="31"
            placeholder="dd"
         >
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "DateInput",
    props: {
        columnName: {
            type: String,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        id: {
            type: Number,
            required: true,
        }
    },
    emits: ["valueChanged"],
    setup(props, { emit }) {
        const store = useStore();
        const currentRange = store.state.advanced_search[props.columnName][props.index][props.id].trim();

        const year = ref(currentRange.split("-").length == 3 && currentRange.split("-")[0].length == 4 ? currentRange.split("-")[0] : "");
        const month = ref(currentRange.split("-").length == 3 && currentRange.split("-")[1].length == 2 ? currentRange.split("-")[1] : "");
        const day = ref(currentRange.split("-").length == 3 && currentRange.split("-")[2].length == 2 ? currentRange.split("-")[2] : "");

        const yearInput = ref(null);
        const monthInput = ref(null);
        const dayInput = ref(null);

        let monthLengths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        function onKeyUp(input) {
            if (input === 'year') {
                if (year.value.length >= 4) {
                    monthInput.value.focus();
                    year.value = year.value.substring(0, 4);
                }
            } else if (input === 'month') {
                if (parseInt(month.value.charAt(0)) > 1) {
                    dayInput.value.focus();
                } else if (month.value.length >= 2) {
                    dayInput.value.focus();
                }
            } else if (input === 'day') {
                if (parseInt(day.value.charAt(0)) > 3 || day.value.length === 2) {
                    validateDate('day');
                } else if (day.value.length > 2) {
                    day.value = day.value.charAt(0) + day.value.slice(-1);
                    validateDate('day');
                }
            }
        }

        function validateDate(input) {
            const intDay = parseInt(day.value);
            const intMonth = parseInt(month.value);
            const intYear = parseInt(year.value);

            // Adjust for leap years
            // if(year.value && (intYear % 400 == 0 || (intYear % 100 != 0 && intYear % 4 == 0))) {
            //     monthLengths[1] = 29;
            // }

            if (intDay > monthLengths[intMonth - 1]) {
                day.value = monthLengths[intMonth - 1].toString();
            }

            if (input === 'day' && !isNaN(intDay)) {
                if (intDay === 0) {
                    day.value = "01";
                }

                day.value = day.value.toString().padStart(2, "0");
            } else if (input === 'month' && !isNaN(intMonth)) {
                if (intMonth === 0) {
                    month.value = "01";
                }

                month.value = month.value.toString().padStart(2, "0");
            } else if (input === 'year' && !isNaN(intYear)) {
                if (intYear === 0) {
                    year.value = new Date().getFullYear();
                }

                year.value = year.value.toString().padStart(4, "0");
            }
        }

        function selectInputContents(input) {
            if (input === 'day') {
                dayInput.value.select();
            } else if (input === 'month') {
                monthInput.value.select();
            } else if (input === 'year') {
                yearInput.value.select();
            }
        }

        watch([day, month, year], () => {
            let formattedDate = "";

            if (day.value.length === 2 && month.value.length === 2 && year.value.length === 4) {
                formattedDate = year.value.toString() + "-" + month.value.toString() + "-" + day.value.toString();
            }

            if (formattedDate) {
                emit("valueChanged", { value: formattedDate, index: props.index, id: props.id })
            }
        });

        watch(() => store.state.advanced_search[props.columnName], () => {
            let date;
            
            try {
                date = store.state.advanced_search[props.columnName][props.index][props.id];
            } catch (error) {
                return;
            }

            if (!date) {
                [year.value, month.value, day.value] = ["", "", ""];
            } else if ([year.value, month.value, day.value] !== date.split("-")) {
                [year.value, month.value, day.value] = date.split("-");
            }
        },
        { deep: true });

        return {
            day,
            month,
            year,
            dayInput,
            monthInput,
            yearInput,
            onKeyUp,
            validateDate,
            selectInputContents,
        }
    }
}
</script>

<style scoped>
    .date-input {
        width: 100%;
        height: 100%;

        display: flex;
        position: relative;
        overflow: hidden;

        align-items: center;

        padding-left: 0.35rem;
        padding-right: 0.5rem;

        border-radius: 5px;
        border: 1px solid var(--highlight-hover-color);

        color: var(--main-text-color);
        background-color: var(--highlight-color);
        transition: 0.2s ease-out;
    }

    .input {
        border-width: 0px;
        border: none;

        font-size: 13.25px;
        text-align: center;

        color: var(--main-text-color);
        background-color: var(--highlight-color);
        transition: 0.2s ease-out;
    }

    .input:focus {
        outline: none;
    }

    ::placeholder {
        color: rgb(117, 117, 117);
        opacity: 1;
    }

    .day {
        width: 25%;
    }

    .month {
        width: 30%;
    }
    
    .year {
        width: 38%;
    }

    .divider {
        text-align: center;
        color: rgb(117, 117, 117);
    }

    /* Hide the number input spinner box on Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Hide it on Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
</style>