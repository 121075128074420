<template>
    <div class="result-card">
        <div class="result-card-header">
            <div class="result-card-title" :style="'--title-width:' + getRandomInt(200, 420).toString() + 'px;'"></div>
        </div>

        <div class="main-text-content">
            <div class="ids">
                <div class="result-card-content id" style="width: 35px; margin-right: 5px"></div> <p style="margin: 0">/</p> <div class="result-card-content id" style="width: 50px; margin-left: 4px"></div>
            </div>

            <div class="result-card-content owner" :style="'--owner-width:' + getRandomInt(150, 250).toString() + 'px;'"></div>

            <div class="description">
                <div class="desc-line result-card-content"></div>
                <div class="desc-line result-card-content"></div>
                <div class="desc-line result-card-content"></div>
                <div class="desc-line result-card-content short"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SearchResult",
    setup() {
        function getRandomInt(min, max) {
            return Math.random() * (max - min) + min;
        }

        return {
            getRandomInt,
        }
    }
}
</script>

<style scoped>
    .result-card {
        min-width: 230px;
        max-width: 540px;
        width: 100%;
        max-height: 12em;

        margin-bottom: 10px;
        padding: 0 15px 0 15px;

        position: relative;

        display: flex;
        flex-direction: column;
        align-items: left;

        background-color: var(--highlight-color);
        border-radius: 5px;

        cursor: pointer;
        transition: 0.2s ease-out;
    }

    .result-card:hover {
        box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
        -webkit-box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
       -moz-box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
        background-color: var(--highlight-hover-color);
    
        transition: 0.08s ease-out;
    }

    .result-card-header {
        margin: 15px 0 5px 0;

        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .result-card-title {
        /* width: 80%; */
        width: var(--title-width);
        height: 15px;

        overflow: hidden;

        border-radius: 2px;
        background-color: var(--skeleton-accent-primary-color);

        transition: 0.2s ease-out;
    }

    .result-card-title::before {
        content: '';
        display: block;
        position: relative;

        left: -150px;
        top: 0;
        height: 15px;
        width: 150px;

        background: var(--skeleton-background);
        animation: load 3s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }

    .main-text-content {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .result-card-content {
        height: 7px;
        /* margin-top: 5px; */

        border-radius: 1px;
        background-color: var(--skeleton-accent-primary-color);

        overflow: hidden;
        transition: 0.2s ease-out;
    }

    .result-card-content::before {
        content: '';
        display: block;
        position: relative;

        left: -150px;
        top: 0;
        height: 5px;
        width: 150px;

        background: var(--skeleton-background);
        animation: load 3s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }

    .ids {
        margin: 0 0 0 0;

        display: flex;
        align-content: center;

        font-size: 0.9rem;
        color: var(--skeleton-accent-primary-color);
    }

    .id {
        margin: 5px 0 0 0;
    }

    .owner {
        width: var(--owner-width);
    }

    .description {
        margin: 5px 0 15px 0;
    }

    .desc-line {
        max-width: 100%;
        height: 5px;

        margin-top: 3px;
    }

    .short {
        width: 150px;
    }

    /* .id {

    }

    .owner {

    } */
</style>