<template>
    <div id="featured-data-table">
        <Suspense>
            <template #default>
                <SatelliteDataTable
                    title=""
                    source="celestrak"
                    :ncn="featuredSatellite"
                    :keysToExclude="['Launch Site', 'Decay Date', 'Data Status', 'Orbit Center', 'Source']"
                    :addToHistory="false"
                /> <!-- Make ncn change daily - get from list -->
            </template>
            <template #fallback>
                <satellite-data-table-skeleton :rowCount="14"/>
            </template>
        </Suspense>
        
        <h5 class="source-label">From CelesTrak</h5>

        <!-- Make the table link to the corresponding data page -->
        <router-link :to="'/satellite/' + featuredSatellite">
            <span class="link-spanner"></span> <!-- Makes the whole div a clickable link -->
        </router-link>
    </div>
</template>

<script>
import { computed } from 'vue';

import SatelliteDataTable from '../satellite/SatelliteDataTable.vue';
import SatelliteDataTableSkeleton from '../../skeleton components/SatelliteDataTableSkeleton.vue';

export default {
    name: 'FeaturedData',
    components: {
        SatelliteDataTable,
        SatelliteDataTableSkeleton,
    },
    setup() {
        const approvedSatellites = [
            "25544",
        ];

        // Get a random satellite from the list of approved satellites
        const featuredSatellite = computed(() => approvedSatellites[Math.floor(Math.random() * approvedSatellites.length)]);

        return {
            featuredSatellite,
        }
    }
}
</script>

<style scoped>
    .source-label {
        margin: 2.5px 0 0 0;
        
        text-align: right;
        font-style: oblique;
        font-size: 0.65rem;

        color: var(--secondary-text-color);
    }

    .link-spanner {
        position: absolute;

        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        z-index: 1;
    }

    #featured-data-table {
        position: relative;

        min-width: 20em;
        width: auto;
        max-width: 33.5em;

        height: auto;

        margin: 0 auto;
        padding: 0 9px 4px 9px;

        background-color: var(--highlight-color);
        border-radius: 5px;

        cursor: pointer;
        transition: 0.2s ease-out;
    }

    #featured-data-table:hover {
        box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
        -webkit-box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
        -moz-box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
        background-color: var(--highlight-hover-color);

        transition: 0.08s ease-out;
    }
</style>