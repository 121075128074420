<template>
    <form autocomplete="off" @submit.prevent="search()">
        <button class="search-button" type="submit" >Search</button>
    </form>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
    name: "SearchButton",
    setup() {
        const router = useRouter();
        const store = useStore();

        function search() {
            router.push({ path: "/search", query: { q: store.state.advanced_search.query, advanced: true } });
        }

        return {
            search,
        }
    }    
}
</script>

<style scoped>
    .search-button {
        width: 150px;
        height: 33px;

        cursor: pointer;
        border-radius: 5px;
        border-color: transparent;
        color: var(--main-text-color);
        background-color: var(--highlight-color);

        font-weight: bold;

        transition: 0.2s ease-out;
    }

    .search-button:hover {
        box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
        background-color: var(--highlight-hover-color);
        background-size: 22px 22px;
    }

    .search-button:active {
        box-shadow: none;
    }
</style>