<template>
    <div class="satellite-data-table">
        <data-table :title="title" :titleLink="titleLink" :jsonData="data" :hasExpandableContent="hasExpandableContent">
            <div class="date-select-container" v-if="showDateSelect && dates.length > 0 && Object.keys(data).length > 0">
                <p class="date-select-label">Showing data for</p>
                <date-select 
                    class="sat-data-date-select"
                    :options="dates"
                    :default="dates[0]"
                    :source="source"
                />
            </div>
        </data-table>
    </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from 'vuex';

import DataTable from '../common/DataTable.vue';
import DateSelect from './DateSelect.vue';

export default {
    name: "SatelliteDataTable",
    components: {
        DataTable,
        DateSelect,
    },
    props: {
        title: {
            type: String,
            required: false,
        },
        titleLink: {
            type: String,
            required: false,
        },
        source: {
            type: String,
            required: false,
        },
        ncn: {
            type: String,
            required: false,
        },
        keysToExclude: {
            type: Array,
            required: false,
        },
        addToHistory: {
            tyep: Boolean,
            required: false,
        },
        showDateSelect: {
            type: Boolean,
            required: false
        },
        hasExpandableContent: {
            type: Boolean,
            required: false,
        },
    },
    async setup(props) {
        const store = useStore();

        let data;
        let dates = [];
        let selectedCelestrak = store.state.satellite_data.selectedData.celestrak;

        if (props.source === "all") {
            let dataRefreshComplete = computed(() => store.getters["satellite_data/dataRefreshComplete"]);
            await store.dispatch("global/until", () => dataRefreshComplete.value == true);
            data = computed(() => store.getters["satellite_data/allData"]);

        } else {
            if (!("ncn" in selectedCelestrak) || selectedCelestrak.ncn != props.ncn) {
                await store.dispatch("satellite_data/refreshData", { source: props.source, ncn: props.ncn, addToHistory: props.addToHistory });
            }

            data = computed(function () {
                let storeData = store.state.satellite_data.selectedData[props.source];
                let returnData = JSON.parse(JSON.stringify(storeData)); // Deep copy of the data

                // Remove all the unwanted data
                if (props.keysToExclude) {
                    for (const key of props.keysToExclude) {
                        delete returnData[key];
                    }
                }

                return returnData; 
            });

            // Get all of the update timestamps from the data for the date dropdown
            const sourceData = store.state.satellite_data.satData[props.source];
            dates = sourceData.map(
                data => data["Update Timestamp"]
            );
        }

        return {
            data,
            dates,
        }
    },
}
</script>

<style scoped>
    .satellite-data-table {
        margin: 0 auto 0 auto;
        max-width: 760px;
        padding: 0;
    }

    .date-select-container {
        display: flex;
        
        justify-content: center;
        align-items: center;

        width: 100%;
        margin: 10px 0;
    }

    .date-select-label {
        margin-right: 10px;

        color: var(--secondary-text-color);
        font-size: 0.85rem;
    }
    
    @media only screen and (max-width: 360px) {
        .date-select-container {
            flex-direction: column;
        }

        .date-select-label {
            margin: 0 0 5px 0;
        }
    }
</style>
