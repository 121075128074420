<template>
    <div class="content-hider" ref="contentHider" :style="{ '--left-offset': leftOffset + 'px', '--hidden-content-width': contentWidth + 'px' }">
        <div class="image-wrapper" :class="{ rotated: showContent }" @click="onContentToggle()">
            <img class="clickable-image" src="../../assets/down_arrow.png" />
        </div>
        
        <transition name="fade">
            <div class="hidden-content" ref="hiddenContent" v-if="showContent">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script>
import { computed, ref, onMounted, onUnmounted } from 'vue';

export default {
    name: "ContentHider",
    setup() {
        function setPositionValues() {
            windowWidth.value = window.innerWidth;
            contentHiderXPos.value = contentHider.value.getBoundingClientRect().left;

            try {
                hiddenContentWidth.value = contentWidth.value;
            } catch {
                hiddenContentWidth.value = 0;
            }
        }

        let showContent = ref(false);

        const windowWidth = ref(window.innerWidth);
        const contentHider = ref(null);
        const hiddenContent = ref(null);

        const contentHiderXPos = ref(0);
        const hiddenContentWidth = ref(0);

        onMounted(() => {
            window.addEventListener("resize", setPositionValues);
            setPositionValues();
        });

        onUnmounted(() => window.removeEventListener("resize", setPositionValues));

        const hiddenContentSpacing = computed(() => {
            let contentHiderWidth;

            try {
                contentHiderWidth = contentHider.value.getBoundingClientRect().width;
            } catch {
                contentHiderWidth = 16; // Assume it's 16 pixels
            }

            // Pixels from the left of the window to the right of the content hider
            let contentHiderRightMostValue = contentHiderXPos.value + contentHiderWidth;
            let hiddenContentSpacing = windowWidth.value - contentHiderRightMostValue;

            return hiddenContentSpacing < 55 ? hiddenContentSpacing : 55;
        });

        const contentWidth = computed(() => {
            // The empty space on the left and right of the hidden content
            let emptySpace = 2 * hiddenContentSpacing.value;

            // The keys in the data table have more spacing than the values - this accounts for that
            let keySpacing = 6;
            let contentWidth = windowWidth.value - emptySpace + keySpacing; 

            return contentWidth < 500 ? contentWidth : 500;

        });

        const leftOffset = computed(() => {
            // Pixels from the left side  of the screen to the right side of the data table
            let hiddenContentRange = windowWidth.value - hiddenContentSpacing.value;

            // Pixels from the left side of the screen to the right side of the hidden content
            let leftWidth = contentHiderXPos.value + hiddenContentWidth.value;

            // Clamp the offset to be <= 0. If it's greater than 0, we don't want it to apply
            return hiddenContentRange - leftWidth <= 0 ? hiddenContentRange - leftWidth : 0;
        });

        function onContentToggle() {
            showContent.value = !showContent.value;

            if (showContent.value) {
                // A slight delay is needed because otherwise the position isn't updated
                setTimeout(() => setPositionValues(), 1);
            }
        }

        return {
            showContent,
            contentHider,
            hiddenContent,
            leftOffset,
            contentWidth,
            setPositionValues,
            onContentToggle,
        }
    },
}
</script>

<style scoped>
    .content-hider {
        position: relative;

        height: 100%;
    }

    .image-wrapper {
        width: 16px;
        height: 100%;
        margin: 0 auto 5px auto;

        display: flex;
        align-items: center;

        cursor: pointer;

        transform: rotate(270deg);
        transition: 0.2s ease-out;
    }

    .clickable-image {
        height: 8px;

        object-fit: contain;
        
        user-select: none;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */

        -webkit-filter: var(--webkit-image-inverter);
        filter: var(--image-inverter);

        transition: 0.2s ease-out;
    }

    .hidden-content {
        position: absolute;

        /* min-width: 500px; */
        width: var(--hidden-content-width);
        margin: 0 auto;
        padding: 3px 10px 10px 10px;

        left: var(--left-offset);
        right: 0;
        
        z-index: 2;

        background-color: var(--main-page-color);

        border-radius: 5px;
        box-shadow: 0 0px 5px 5px var(--light-box-shadow-color);

        transition: background-color 0.2s ease-out;
    }

    .rotated {
        transform: rotate(360deg);
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.2s ease;
    }

    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }
</style>
