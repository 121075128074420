<template>
    <footer>
        <!-- Using a section now allows for easy addition in the future -->
        <section id="main-footer">
            <div class="footer-item" id="title">
                <h2>Satellite Data Viewer</h2>
                <p>From <a href="https://intrack.solutions/">InTrack Solutions</a></p>
            </div>

            <!-- <div class="footer-item">
                <h3><router-link to="/about" class="footer-title-link">About</router-link></h3>
                <ul>
                    <li><router-link to="/about/us">Who We Are</router-link></li>
                    <li><router-link to="/about/products">Products</router-link></li>
                    <li><router-link to="/about/api">API</router-link></li>
                </ul>
            </div>

            <div class="footer-item">
                <h3><router-link to="/sources" class="footer-title-link">Sources</router-link></h3>
                <ul>
                    <li><a href="https://celestrak.com/" target="_blank" rel="noopener noreferrer">CelesTrak</a></li>
                    <li><a href="https://planet4589.org/" target="_blank" rel="noopener noreferrer">Planet4589</a></li>
                    <li><a href="https://www.ucsusa.org/" target="_blank" rel="noopener noreferrer">UCS</a></li>
                </ul>
            </div> -->

            <div class="footer-item" id="intrack-logo-wrapper">
                <img id="intrack-logo" src="../../assets/horizontal_intrack_logo.png">
            </div>
        </section>
    </footer>
</template>

<style scoped>
    footer {
        line-height: 1.5;
        text-align: left;
    }

    #main-footer {
        padding: 0 1.5rem; /* looks best with 1.25rem 1.875rem but that makes the home screen scroll */
        display: flex;
        flex-wrap: wrap;
        background-color: var(--footer-color);

        transition: 0.2s ease-out;
    }

    #title {
        margin: auto 60px auto 20px;
        line-height: 0.5;
    }

    #title p, #title a{
        text-decoration: none;
        font-style: oblique;
        font-weight: 550;
        color: var(--secondary-text-color);
    }

    #title a:hover {
        text-decoration: underline;
    }

    #intrack-logo-wrapper {
        margin-left: auto;
        margin-right: 1.5rem;
        padding-top: 40px;
    }

    #intrack-logo {
        width: 350px;
        object-fit: contain;
    }

    .footer-item {
        padding: 1.25rem;
        min-width: 12.5rem;
    }

    .footer-title-link {
        color: var(--main-text-color);
        text-decoration: none;
    }
    
    .footer-title-link:hover {
        text-decoration: underline;
    }

    ul {
        list-style-type: none;
        padding-left: 0;
    }

    ul li a {
        text-decoration: none;
        color: var(--footer-link-color);
    }

    ul li a:hover {
        text-decoration: underline;
    }

    @media only screen and (max-width: 1222px) {
        #intrack-logo-wrapper {
            margin-left: 0;
        }
    }

    @media only screen and (max-width: 814px) {
        #main-footer {
            font-size: 0.75rem;
        }
    }

    @media only screen and (max-width: 755px) {
        #title {
            width: 100%;
            margin: auto;
        }
    }

    @media only screen and (max-width: 459px) {
        .footer-item {
            margin: 0;
        }

        #intrack-logo {
            width: 100%;
        }
    }
</style>