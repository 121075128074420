<template>
    <div id="title-area">
        <Title />
        <p class="subtext">Easily view satellite information from a variety of sources. <br><br id="small-screen-line-break">Get started by searching for a specific satellite or following a quick link.</p>
    </div>

    <div id="main-search-area">
        <h2>Find a Satellite</h2>
        <Searchbar showAdvancedSearchLink/>
    </div>

    <div id="columns">
        <div class="column" id="news">
            <h2>News</h2>
            <Suspense>
                <template #default>
                    <news />
                </template>
                <template #fallback>
                    <news-skeleton />
                </template>
            </Suspense>
        </div>

        <div class="column" id="links">
            <div class="link-list" id="recent-searches" v-if="recentSearches.length  > 0">
                <h2>Recently Viewed</h2>
                
                <div id="links-and-buttons">
                    <QuickLink
                        v-for="satellite in recentSearches" 
                        :key="satellite" 
                        :displayText="satellite.name + ' - ' + satellite.ncn" 
                        :routerLink="'/satellite/' + satellite.ncn" 
                        :showLink="false"
                    />
                </div>
            </div>

            <div class="link-list" id="quick-links">
                <h2>Quick Links</h2>
                <!-- TODO: Load dynamically from file (similar system to featured data) -->
                <!-- TODO: Make editable -->
                <QuickLink displayText="ISS (ZARYA) - 25544" routerLink="/satellite/25544" :showLink="false" />
                <QuickLink displayText="ISEE-3 (ICE) - 11004" routerLink="/satellite/11004" :showLink="false" />
                <QuickLink displayText="HST - 20580" routerLink="/satellite/20580" :showLink="false" />
                <QuickLink displayText="SPUTNIK 1 - 00002" routerLink="/satellite/00002" :showLink="false" />
                <QuickLink displayText="CASSINI - 25008" routerLink="/satellite/25008" :showLink="false" />
            </div>
        </div>

        <div class="column" id="featured-data">
            <h2>Featured Data</h2>
            <featured-data />
        </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

import Title from '../components/common/Title.vue';
import Searchbar from '../components/search/Searchbar.vue';

import QuickLink from '../components/common/QuickLink.vue';
import FeaturedData from '../components/columns/FeaturedData.vue';
import News from '../components/columns/News.vue';

import NewsSkeleton from '../skeleton components/NewsSkeleton.vue';

export default {
    name: 'Home',
    components: {
        Title,
        Searchbar,

        QuickLink,
        FeaturedData,
        News,
        
        NewsSkeleton,
    },
    setup() {
        const store = useStore();

        function clearSearchHistory() {
            store.commit('home/clearSearchHistory');
        }

        return {
            recentSearches: computed(() => store.state.home.recentSearches),
            clearSearchHistory,
        }
    }
}
</script>

<style>
    .subtext {
        color: gray;
    }

    .column {
        flex: 1;
    }

    .link-list {
        margin-top: 20px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .link-spanner {
        position:relative;

        width:100%;
        height:100%;
        top:0;
        left: 0;

        z-index: 1;
    }

    #main-search-area {
        margin-top: 50px;
    }

    #columns {
        display: flex;
        max-width: 1775px;
        margin: 0 auto;
    }

    #news, #featured-data {
        margin: 20px 0 0 0;
    }

    #news, #featured-data h2 {
        margin-bottom: 20px;
    }

    #links-and-buttons {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #clear-button {
        width: fit-content;
        margin-left: auto;

        border: 0.1em solid var(--inverted-color);
        border-radius: 5px;

        text-decoration:none;

        font-weight:300;

        color: var(--main-text-color);
        background-color: var(--main-color);

        cursor: pointer;
        transition: 0.175s ease-out;
    }

    #clear-button:hover {
        background-color: var(--button-background-hover-color);
    }

    #small-screen-line-break {
        display: none;
    }

    @media only screen and (max-width: 1830px) {
        #columns {
            flex-direction: column;
        }

        #links {
            order: 0;
        }

        #news {
            order: 1;
        }

        #featured-data {
            order: 2;
        }
    }

    @media only screen and (max-width: 450px) {
        #small-screen-line-break {
            display: initial;
        }
    }
</style>
