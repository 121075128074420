const search = {
    namespaced: true,
    state: {
        order: "relevance",
        relevantResults: [],
    },
    mutations: {
        setProperty(state, { property, newProperty }) {
            state[property] = newProperty;
        },
    },
}

export default search;
