import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';

import global from './modules/global';
import home from './modules/home';
import satellite_data from './modules/satellite_data';
import search from './modules/search';
import advanced_search from './modules/advanced_search';

export default createStore({
    modules: {
        global,
        home,
        satellite_data,
        search,
        advanced_search,
    },
    plugins: [
        createPersistedState({
            paths: ["global", "home", "advanced_search"],
        })
    ],
})
