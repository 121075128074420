<template>
    <h1><router-link to="/">Satellite Data Viewer</router-link></h1>
</template>

<script>
export default {
  name: 'Title',
  props: {
    msg: String
  }
}
</script>

<style scoped>
    a {
        text-decoration: none;
        color: var(--main-text-color);

        user-select: none;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */

        transition: 0.2s ease-out;
    }
</style>
