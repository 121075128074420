<template>
    <div class="searchbar-wrapper">
        <form class="searchbar" autocomplete="off" @submit.prevent="search()">
            <input type="text" placeholder="Search Terms..." class="input" name="input" v-model.trim="textInput" @keyup="process()">
            <button class="search-button" type="submit">
                <img class="search-icon" src="../../assets/search_icon.png" width="25" height="25">
            </button>
        </form>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
    name: 'AdvancedSearchInput',
    setup() {
        const store = useStore();
        const router = useRouter();
        const textInput = ref(store.state.advanced_search.query);

        function search() {
            router.push({ path: "/search", query: { q:textInput.value, advanced: true } });
        }

        function process() {
            store.commit('advanced_search/setOption', { option: "query", newValue: textInput.value });
        }

        return {
            textInput,
            search,
            process,
        }
    }
}
</script>

<style scoped>
    .searchbar-wrapper {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .searchbar {
        margin: 0 auto 0 auto;

        display: flex;
        box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
        border-radius: 5px;
        max-width: 540px;
        min-width: 230px;
    }

    .input {
        max-width: 540px;
        min-width: 230px;
        
        width: 100%;
        height: 40.5px;

        padding-left: 8px;
        
        color: var(--main-text-color);
        background-color: var(--searchbar-color);
        
        border-radius: 5px 0 0 5px;
        border-color: transparent;

        /* -webkit-appearance: none;
        -moz-appearance: none; */

        font-family: Avenir, Helvetica, Arial, sans-serif;
        font-size: 1.125rem;
        
        transition: 0.2s ease-out;
    }

    .search-button {
        width: 45px;
        height: 40.5px;

        background: var(--highlight-color);
        cursor: pointer;

        border-radius: 0 5px 5px 0;
        border-width: 0 0 0 1px;
        border-color: var(--highlight-hover-color);

        transition: 0.2s ease-out;
    }

    .search-button:hover {
        background: var(--highlight-hover-color);
    }

    .search-button:focus {
        outline: none;
    }

    .search-icon {
        opacity: 0.35;
        
        filter: var(--image-inverter);
        -webkit-filter: var(--webkit-image-inverter);

        transition: 0.08s ease-out;
        image-rendering: -webkit-optimize-contrast;
    }

    ::placeholder {
        font-size: 1.125rem;
    }

    input:focus {
        outline: none;
    }

    form {
        width: 100%;
    }

    @media only screen and (max-width: 358px) {
    
    }
</style>
