import { computed } from 'vue';

const satellite_data = {
	namespaced: true,
	state: {
		satData: {
			celestrak: [], // CelesTrak
			planet4589: [], // Jonathan's Space Report (Planet4589)
			ucs: [], // The Union of Concerned Scientists
			nssdca: [], // NASA Space Science Data Coordinated Archive
		},

		selectedData: {
			celestrak: {},
			planet4589: {},
			ucs: {},
			nssdca: {},
			name_cache: {
				ncn: "",
				name: "",
			},
			description_cache: {
				ncn: "",
				description: "",
			}
		},

		// Used to determine if the data refresh has been completed for each source
		completedSources: {
			celestrak: false,
			planet4589: false,
			ucs: false,
			nssdca: false,
		},

		planet4589Translations: {
			organizations: {},
		}
	},
	mutations: {
		setSatData(state, { source, dataArray }) {
			state.satData[source] = dataArray;
		},

		setSelectedData(state, { source, dataObject }) {
			state.selectedData[source] = dataObject;
		},

		setDataPoint(state, { source, dataObject, dataPoint }) {
			state.selectedData[source][dataPoint] = dataObject;
		},

		setTranslations(state, { table, dataObject }) {
			state.planet4589Translations[table] = dataObject;
		},

		setCompletedSource(state, { source, isCompleted }) {
			state.completedSources[source] = isCompleted;
		},

		setAllSourceCompletion(state, isCompleted) {
			for (const source in state.completedSources) {
				state.completedSources[source] = isCompleted;
			}
		},
	},
	actions: {
		async refreshData(context, { source, ncn, addToHistory }) {
			context.commit("setCompletedSource", {
				source: source,
				isCompleted: false,
			});

			let data;

			// Get data from the cookie if available. If not, fetch the data using the API
			const payload = {
				table: source,
				"column names": "NORAD Catalog Number",
				"column values": ncn.replace(/^0+/, ""),
				"order by columns": "Update Timestamp",
				"order by directions": "DESC",
				"row count": "25",
				"return raw data": source === "planet4589" ? "true" : "false",
			};

			const response = await fetch(
				"https://satellite-data-interface.intrack.solutions/api/query_db?code=SD8KHG959qa9nv6IMOLxmk1gSlBsdlabJ2O8RSXt8lp2q9mfaNMD0A==",
				{ method: "POST", body: JSON.stringify(payload), mode: "cors" }
			);

			data = await response.json();

			// There should always be something to loop through when rendering the data tables
			if (Object.keys(data).length === 0 || data == null) {
				data = [{}];
			}

			// Apply translations to planet4589 data
			if (source === "planet4589") {
				// The translations object is empty by default, and is set to null if no translations are available
				let translationsAvailable = computed(() => context.state.planet4589Translations.organizations === null ||
					Object.keys(context.state.planet4589Translations.organizations).length > 0
				);

				await context.dispatch("global/until", () => translationsAvailable.value == true, { root: true });

				data.forEach(row => {
					Object.keys(row).forEach((element) => {
						if (!["Owner", "Manufacturer", "State"].includes(element)) {
							return;
						}

						context.state.planet4589Translations.organizations.forEach(organization => {
							if (organization["Code"] === row[element]) {
								row[element] = organization;
							}
						});
					});
				});
			}

			data.map((element) => {
				if ("Inclination" in element && element["Inclination"]) {
					element["Inclination"] = element["Inclination"] + "°";
				}

				if ("Inc" in element && element["Inc"]) {
					element["Inc"] = element["Inc"] + "°";
				}

				if (
					"Longitude of GEO" in element &&
					element["Longitude of GEO"]
				) {
					element["Longitude of GEO"] =
						element["Longitude of GEO"] + "°";
				}

				if ("Period" in element && element["Period"]) {
					element["Period"] = element["Period"] + " minutes";
				}

				if ("Perigee" in element && element["Perigee"]) {
					element["Perigee"] = element["Perigee"] + " km";
				}

				if ("Apogee" in element && element["Apogee"]) {
					element["Apogee"] = element["Apogee"] + " km";
				}

				if ("Mass" in element && element["Mass"]) {
					element["Mass"] = element["Mass"] + " kg";
				}

				if ("Launch Mass" in element && element["Launch Mass"]) {
					element["Launch Mass"] = element["Launch Mass"] + " kg";
				}

				if ("Dry Mass" in element && element["Dry Mass"]) {
					element["Dry Mass"] = element["Dry Mass"] + " kg";
				}

				if ("DryMass" in element && element["DryMass"]) {
					element["DryMass"] = element["DryMass"] + " kg";
				}

				if ("TotMass" in element && element["TotMass"]) {
					element["TotMass"] = element["TotMass"] + " kg";
				}

				if ("Power" in element && element["Power"]) {
					element["Power"] = element["Power"] + " watts";
				}

				if (
					"Expected Lifetime" in element &&
					element["Expected Lifetime"]
				) {
					element["Expected Lifetime"] =
						element["Expected Lifetime"] + " years";
				}
			});

			// Set all the data (with the intial selected data set to the most recent)
			context.commit("setSelectedData", {
				source: source,
				dataObject: data[0],
			});
			context.commit("setSatData", { source: source, dataArray: data });

			// Stop satellite from being added four times (one for each source)
			if (
				source === "celestrak" &&
				context.state.satData["celestrak"][0]["Name"] &&
				addToHistory
			) {
				context.commit(
					"home/addRecent",
					{
						ncn: ncn,
						name: context.state.satData["celestrak"][0]["Name"],
					},
					{ root: true }
				);
			}

			context.commit("setCompletedSource", {
				source: source,
				isCompleted: true,
			});
		},

		async refreshDataPoint(context, { source, ncn, dataPoint }) {
			let data;

			// Get data from the cookie if available. If not, fetch the data using the API
			const payload = {
				table: source,
				"column names": "NORAD Catalog Number",
				"column values": ncn.replace(/^0+/, ""),
				"order by columns": "Update Timestamp",
				"order by directions": "DESC",
				"row count": "25",
				"return raw data": "false",
				"columns to return": dataPoint,
			};

			const response = await fetch(
				"https://satellite-data-interface.intrack.solutions/api/query_db?code=SD8KHG959qa9nv6IMOLxmk1gSlBsdlabJ2O8RSXt8lp2q9mfaNMD0A==",
				{ method: "POST", body: JSON.stringify(payload), mode: "cors" }
			);

			data = await response.json();

			// There should always be something to loop through when rendering the data tables
			if (Object.keys(data).length === 0 || data == null) {
				data = [];
				return;
			}

			data.map((element) => {
				if ("Inclination" in element && element["Inclination"]) {
					element["Inclination"] = element["Inclination"] + "°";
				}

				if ("Inc" in element && element["Inc"]) {
					element["Inc"] = element["Inc"] + "°";
				}

				if (
					"Longitude of GEO" in element &&
					element["Longitude of GEO"]
				) {
					element["Longitude of GEO"] =
						element["Longitude of GEO"] + "°";
				}

				if ("Period" in element && element["Period"]) {
					element["Period"] = element["Period"] + " minutes";
				}

				if ("Perigee" in element && element["Perigee"]) {
					element["Perigee"] = element["Perigee"] + " km";
				}

				if ("Apogee" in element && element["Apogee"]) {
					element["Apogee"] = element["Apogee"] + " km";
				}

				if ("Mass" in element && element["Mass"]) {
					element["Mass"] = element["Mass"] + " kg";
				}

				if ("Launch Mass" in element && element["Launch Mass"]) {
					element["Launch Mass"] = element["Launch Mass"] + " kg";
				}

				if ("Dry Mass" in element && element["Dry Mass"]) {
					element["Dry Mass"] = element["Dry Mass"] + " kg";
				}

				if ("DryMass" in element && element["DryMass"]) {
					element["DryMass"] = element["DryMass"] + " kg";
				}

				if ("TotMass" in element && element["TotMass"]) {
					element["TotMass"] = element["TotMass"] + " kg";
				}

				if ("Power" in element && element["Power"]) {
					element["Power"] = element["Power"] + " watts";
				}

				if (
					"Expected Lifetime" in element &&
					element["Expected Lifetime"]
				) {
					element["Expected Lifetime"] =
						element["Expected Lifetime"] + " years";
				}
			});

			// Set all the data (with the intial selected data set to the most recent)
			context.commit("setDataPoint", {
				source: source,
				dataObject: data[0],
				dataPoint: dataPoint,
			});
			// context.commit("setSelectedData", { source: source, dataObject: data[0] });
			// context.commit("setSatData", { source: source, dataArray: data });
		},

		async getTranslationData(context) {
			const response = await fetch(
				"https://satellite-data-interface.intrack.solutions/api/query_db?code=SD8KHG959qa9nv6IMOLxmk1gSlBsdlabJ2O8RSXt8lp2q9mfaNMD0A==",
				{ method: "POST", body: JSON.stringify({ table: "planet4589_organizations" }), mode: "cors" }
			);

			let data = await response.json();

			context.commit("setTranslations", { 
				table: "organizations",
				dataObject: data !== undefined || data !== null || Object.keys(data).length > 0 ? data : null
			});
		}
	},
	getters: {
		ncnValid(state) {
			if (
				Object.keys(state.selectedData.celestrak).length > 0 ||
				Object.keys(state.selectedData.planet4589).length > 0 ||
				Object.keys(state.selectedData.ucs).length > 0 ||
				Object.keys(state.selectedData.nssdca).length > 0
			) {
				return true;
			}

			// No source has any data, thus the NORAD Catalog Number is deemed invalid
			return false;
		},

		allData(state) {
			// Seperate computed variables are used so that allData updates when the state changes
			const ucsData = state.satData.ucs;
			const planet4589Data = state.satData.planet4589;
			const celestrakData = state.satData.celestrak;

			// Combine all the most recent data into a single object
			let mergedData = Object.assign(
				{},
				ucsData[0],
				planet4589Data[0],
				celestrakData[0]
			);

			// Manually remove all of the shared data points with different names
			delete mergedData["Piece"];
			delete mergedData["LDate"];
			delete mergedData["Inc"];
			delete mergedData["OpOrbitOQU"];

			delete mergedData["Current Official Name of Satellite"];
			delete mergedData["Name of Satellite, Alternate Names"];
			delete mergedData["Operator/Owner"];
			delete mergedData["Dry Mass"];
			delete mergedData["Date of Launch"];
			delete mergedData["Expected Lifetime"];
			delete mergedData["COSPAR Number"];
			delete mergedData["Source Used for Orbital Data"];
			delete mergedData["Sources"];

			delete mergedData["Update Timestamp"];

			return mergedData;
		},

		dataRefreshComplete(state) {
			// NSSDCA isn't included in "All Data", but it's usually finished first, and so won't
			// have an impact on latency
			const dataRefreshComplete =
				state.completedSources.celestrak &&
				state.completedSources.planet4589 &&
				state.completedSources.ucs &&
				state.completedSources.nssdca;

			return dataRefreshComplete;
		},
	},
};

export default satellite_data;
