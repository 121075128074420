<template>
    <div class="column-filter">
        <p class="column-name">{{ columnName }}:</p>

        <form class="filter" autocomplete="off" @submit.prevent>
            <textual-column-dropdown
                class="input"
                v-for="(input, index) in inputs" :key="index"
                :options="options"
                :default="{ title: placeholder, value: '' }"
                :columnName="columnName"
                :index="index"
                ref="dropdowns"
                @valueChanged="changeValue"
            />

            <button
                class="add-button"
                type="button"
                @click="addInput()"
                :disabled="!inputsFilled"
            >
                <img class="add-icon" src="../../assets/add_icon.png" width="18" height="18">
            </button>
        </form>

        <div class="remove-buttons">
            <button
                class="remove-button"
                type="button"
                v-for="(input, index) in inputs"
                :key="index"
                @click="removeInput(index)"
                :disabled="oneInput && !inputsFilled"
            >
                <img class="remove-icon" src="../../assets/remove_icon.png" width="10" height="10">
            </button>
        </div>
    </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';

import TextualColumnDropdown from "./TextualColumnDropdown.vue";

export default {
    name: "ColumnFilter",
    components: {
        TextualColumnDropdown,
    },
    props: {
        columnName: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            required: false,
            default: "",
        },
        options :{
            type: Array,
            required: true,
        },
    },
    setup(props) {
        const store = useStore();
        const inputs = ref(store.state.advanced_search[props.columnName] ? store.state.advanced_search[props.columnName] : [""]);

        const inputsFilled = computed(() => {
            return inputs.value.every(e => e);
        });
        const oneInput = computed(() => {
            return inputs.value.length <= 1;
        });

        watch(() => inputs, () => {
            store.commit("advanced_search/setOption", { option: props.columnName, newValue: inputs });
        },
        { deep: true });

        function addInput() {
            if (inputsFilled.value) {
                inputs.value.push("");
            }
        }

        function removeInput(index) {
            if (oneInput.value) {
                addInput();
            }

            inputs.value.splice(index, 1);
        }

        function changeValue({ value, index }) {
            inputs.value[index] = value;
        }

        return {
            inputs,
            inputsFilled,
            oneInput,
            addInput,
            removeInput,
            changeValue,
        }
    },
}
</script>

<style scoped>
    .column-filter {
        max-width: 500px;
        min-width: 230px;
        width: 100%;

        margin: 0 auto 30px auto;

        display: flex;
    }

    .column-name {
        margin: 6px 0 0 0;
        font-weight: bold;        
    }

    .filter {
        max-width: 235px;
        width: 100%;
        margin-left: auto;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .dropdown-wrapper {
        height: 30px;
        margin-bottom: 5px;

        display: flex;
        align-items: center;
    }

    .input {
        width: 100%;
        height: 30px;
        margin-bottom: 5px;
    }

    .add-button {
        width: 100%;
        height: 30px;

        margin-top: 5px;

        cursor: pointer;
        border-radius: 5px;
        border-color: transparent;
        background-color: var(--highlight-color);

        transition: 0.2s ease-out;
    }

    .add-button:hover {
        box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
        background-color: var(--highlight-hover-color);
        background-size: 22px 22px;
    }

    .add-button:active {
        box-shadow: none;
    }

    .add-button:disabled {
        box-shadow: none;
        background-color: var(--highlight-color);
        cursor: not-allowed;
        opacity: 0.5;
    }

    .add-icon {
        opacity: 0.35;
        
        filter: var(--image-inverter);
        -webkit-filter: var(--webkit-image-inverter);

        transition: 0.2s ease-out;
        image-rendering: -webkit-optimize-contrast;
    }

    .add-button:hover > .add-icon {
        transform: scale(1.2);
    }

    .add-button:active > .add-icon {
        transform: scale(1);
    }

    .remove-buttons {
        min-width: 30px;
        max-width: 30px;

        margin-left: 5px;
    }

    .remove-button {
        width: 100%;
        height: 30px;

        margin-bottom: 5px;

        cursor: pointer;
        border-radius: 5px;
        border-color: transparent;
        background-color: var(--highlight-color);

        transition: 0.2s ease-out;
    }

    .remove-button:hover {
        background-color: var(--highlight-hover-color);
        background-size: 22px 22px;
    }

    .remove-button:active {
        box-shadow: none;
    }

    .remove-button:disabled {
        box-shadow: none;
        background-color: var(--highlight-color);
        cursor: not-allowed;
        opacity: 0.5;
    }

    .remove-icon {
        opacity: 0.35;
        
        filter: var(--image-inverter);
        -webkit-filter: var(--webkit-image-inverter);

        transition: 0.2s ease-out;
        image-rendering: -webkit-optimize-contrast;
    }

    .remove-button:hover > .remove-icon {
        transform: scale(1.2);
    }

    .remove-button:active > .remove-icon {
        transform: scale(1);
    }

    .remove-button:disabled > .remove-icon {
        opacity: 0.25;
    }
</style>