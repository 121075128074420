<template>
    <div class="json-table">
        <table>
            <tr v-for="rowNumber in rowCount" :key="rowNumber">
                <td
                    class="json-key"
                    :style="'--key-width:' + (100 + getRandomInt(-50, 50)).toString() + 'px;'"
                >
                </td>

                <td
                    class="json-value"
                    :style="'--value-width: ' + (100 - getRandomInt(-50, 50)).toString() + 'px;'"
                >
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    name: "TableSkeleton",
    props: {
        rowCount: {
            type: Number,
            required: true,
        },
    },
    setup() {
        function getRandomInt(min, max) {
            return Math.random() * (max - min) + min;
        }

        return {
            getRandomInt,
        }
    }
}
</script>

<style scoped>
    table {
        width: 100%;
        margin-top: 6px;
        border-collapse: collapse;
    }

    tr {
        display: flex;
        padding: 8px 0;
        border-bottom: thin solid var(--secondary-border-color);
    }

    td {
        max-width: 100%;
        word-wrap: break-word;
    }

    .json-table {
        overflow: auto;

        background-repeat: no-repeat;
        background-attachment: local, local, scroll, scroll;
    }

    .json-key {
        --key-width: 150px;
        width: var(--key-width);
        height: 20px;
        margin: 0 50px 0 5px;

        overflow: hidden;

        border-radius: 3px;
        background-color: var(--skeleton-accent-primary-color);

        transition: 0.2s ease-out;
    }

    .json-key::before {
        content: '';
        display: block;
        position: relative;

        left: -150px;
        top: 0;
        height: 100%;
        width: 150px;

        background: var(--skeleton-background);
        animation: load 3s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }

    .json-value {
        --value-width: 150px;
        width: var(--value-width);
        height: 20px;
        margin-left: auto;

        overflow: hidden;

        border-radius: 3px;
        background-color: var(--skeleton-accent-primary-color);

        transition: 0.2s ease-out;
    }

    .json-value::before {
        content: '';
        display: block;
        position: relative;

        left: -150px;
        top: 0;
        height: 100%;
        width: 150px;
        
        background: var(--skeleton-background);
        animation: load 3s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }

    @media only screen and (max-width: 435px) {
        tr {
            flex-direction: column;
        }

        .json-key {
            height: 18px;
            margin-bottom: 4px;
        }

        .json-value {
            height: 18px;
            margin-left: 5px;

            margin-right: auto;
        }
    }
</style>