<template>
    <searchbar :text="query" showAdvancedSearchLink/>

    <div id="results-listing" v-if="results.length && !loading">
        <h2 id="results-listing-title">Showing {{ results.length }} Results</h2>
        
        <div class="search-order-dropdown">
            <p class="search-order-text">Order by:</p>
            <results-order-dropdown
                :options="[{ value: 'relevance', title: 'Relevance' }, { value: 'name', title: 'Name (alphabetically)' }, { value: 'ncn low to high', title: 'NORAD ID (low to high)' }, { value: 'ncn high to low', title: 'NORAD ID (high to low)' }, { value: 'launch date ascending', title: 'Launch date (ascending)' }, { value: 'launch date descending', title: 'Launch date (descending)' }, { value: 'decay date ascending', title: 'Decay date (ascending)' }, { value: 'decay date descending', title: 'Decay date (descending)' }]"
                :default="{ value: 'relevance', title: 'Relevance' }"
            />
        </div>

        <div class="results">
            <search-result v-for="result in results" :key="result['NORAD Catalog Number']" :result="result"/>
        </div>
    </div>

    <div id="results-loading" v-else-if="loading">
        <h2 id="results-listing-title">Loading Results...</h2>

        <div class="search-order-dropdown">
            <p class="search-order-text">Order by:</p>
            <order-dropdown-skeleton />
        </div>

        <div class="results">
            <search-result-skeleton />
            <search-result-skeleton />
            <search-result-skeleton />
            <search-result-skeleton />
            <search-result-skeleton />
            <search-result-skeleton />
            <search-result-skeleton />
            <search-result-skeleton />
            <search-result-skeleton />
            <search-result-skeleton />
        </div>
    </div>

    <div id="no-results" v-else>
        <h2 id="results-listing-title">No Results</h2>
        <p id="no-results-text">Try searching for some other terms</p>
    </div>
</template>

<script>
import { onBeforeMount, computed, ref, watch } from 'vue';
import { onBeforeRouteUpdate, useRoute } from 'vue-router';
import { useStore } from 'vuex';

import Searchbar from '../components/search/Searchbar.vue';
import ResultsOrderDropdown from '../components/search/ResultsOrderDropdown.vue';
import OrderDropdownSkeleton from '../skeleton components/OrderDropdownSkeleton.vue';
import SearchResult from '../components/search/SearchResult.vue';
import SearchResultSkeleton from '../skeleton components/SearchResultSkeleton.vue';

export default {
    name: "Search",
    components: {
        Searchbar,
        ResultsOrderDropdown,
        OrderDropdownSkeleton,
        SearchResult,
        SearchResultSkeleton,
    },
    setup() {
        const route = useRoute();
        const query = computed(() => route.query.q);
        const advanced = computed(() => {
            if (route.query.advanced)
                return true
            
            return false
        });

        const store = useStore();

        const results = ref([]);
        const loading = ref(true);

        async function getResults(query) {
            loading.value = true;

            let payload = {
                "query": query.replace(/^0+/, ''), // Remove preceding 0's from query
                "row count": "25",
            }

            let response = [];

            if (advanced.value) {
                const advancedSearchOptions = { ...store.state.advanced_search };
                delete advancedSearchOptions["query"]; // Query is already in payload, so can be deleted

                for (const property in advancedSearchOptions) {
                    let value = advancedSearchOptions[property];
                    
                    // Convert both value and [["", ""]] to strings to compare them
                    if (!value || JSON.stringify(value) == JSON.stringify([["", ""]]) || JSON.stringify(value) == JSON.stringify([""])) {
                        continue;
                    }

                    if (typeof(value) !== 'object') {
                        payload[property] = value;
                    } else {
                        const filteredValues = value.filter(el => el !== "" && JSON.stringify(el) !== JSON.stringify(["", ""]) && el !== null && typeof(el) !== undefined);

                        if (filteredValues.length > 0) {
                            payload[property] = filteredValues;
                        }
                    }
                }

                response = await fetch(
                    "https://satellite-data-interface.intrack.solutions/api/advanced_search_db?code=roLCFvXhrNaDRQfpuBhZ9mVAHjOdPCrwE0aD6F5ktBGU4cabOlzdag==", 
                    { method: "POST", body: JSON.stringify(payload), mode: "cors" }
                );
            } else {
                response = await fetch(
                    "https://satellite-data-interface.intrack.solutions/api/search_db?code=lCQIhLaFa2exP1MGi3fQk9NwjjPVXAIT076mjKVmPFdDk/eVPQw4Ng==", 
                    { method: "POST", body: JSON.stringify(payload), mode: "cors" }
                );
            }
            
            results.value = await response.json();

            try {
                store.commit("search/setProperty", { property: "relevantResults", newProperty: [ ...results.value ] });
            } catch {
                store.commit("search/setProperty", { property: "relevantResults", newProperty: [] });
            }

            loading.value = false;
        }

        onBeforeMount(async () => {
            await getResults(query.value);
        });

        onBeforeRouteUpdate(async (to) => {
            await getResults(to.query.q);
        });

        watch(() => store.state.search["order"], () => {
            if (loading.value) {
                return;
            }

            const ordering = store.state.search["order"];

            if (ordering === "relevance") {
                results.value = store.state.search["relevantResults"];
            } else if (ordering === "name") {
                results.value.sort((e1, e2) => e1["Name"].localeCompare(e2["Name"]));
            }  else if (ordering === "ncn low to high") {
                results.value.sort((e1, e2) => parseInt(e1["NORAD Catalog Number"]) - parseInt(e2["NORAD Catalog Number"]));
            } else if (ordering === "ncn high to low") {
                results.value.sort((e1, e2) => parseInt(e2["NORAD Catalog Number"]) - parseInt(e1["NORAD Catalog Number"]));
            } else if (ordering === "launch date ascending") {
                results.value.sort((e1, e2) => new Date(e1["Launch Date"]) - new Date(e2["Launch Date"]));
            } else if (ordering === "launch date descending") {
                results.value.sort((e1, e2) => new Date(e2["Launch Date"]) - new Date(e1["Launch Date"]));
            } else if (ordering === "decay date ascending") {
                results.value.sort((e1, e2) => new Date(e1["Decay Date"]) - new Date(e2["Decay Date"]));
            } else if (ordering === "decay date descending") {
                results.value.sort((e1, e2) => new Date(e2["Decay Date"]) - new Date(e1["Decay Date"]));
            }
        },
        { deep: true });

        return {
            results,
            query,
            loading,
        }
    },
}
</script>

<style scoped>
    #results-listing-title {
        margin: 25px 0 8px 0;
    }

    #no-results-text {
        margin: 0;
    }

    .search-order-dropdown {
        min-width: 230px;
        max-width: 540px;
        width: 100%;

        margin: 0 auto 10px auto;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .search-order-text {
        margin-right: 10px;

        color: var(--secondary-text-color);
        font-size: 0.85rem;
    }

    .results {
        width: 100%;
        margin: 0 auto 0 auto;

        display: flex;
        flex-direction: column;
        align-items: center;
    }
</style>