const advanced_search = {
    namespaced: true,
    state: {
        "query": "",
        "orbitRequired": "false",

        // Textual filters
        "Name": [""],
        "NORAD Catalog Number": [""],
        "International Designator": [""],
        "Launch Site": [""],
        "Status": [""],

        // Date filters
        "Launch Date": [["", ""]],
        "Decay Date": [["", ""]],

        // Numeric filters
        "Apogee": [["", ""]],
        "Perigee": [["", ""]],
        "Period": [["", ""]],
        "Inclination": [["", ""]],
        "Mass": [["", ""]],
    },
    mutations: {
        setOption(state, { option, newValue }) {
            state[option] = newValue;
        },
        reset(state) {
            for (const property in state) {
                if (typeof(property) == String) {
                    state[property] = property == "orbitRequired" ? "false" : "";
                } else if (typeof(property) == Array) {
                    state[property] == [""];
                }
            }
        }
    },
}

export default advanced_search;
