<template>
    <div class="news-card">
        <div class="main-content">
            <div class="image-wrapper">
                <div class="thumbnail"></div>
            </div>

            <div class="main-text-content">
                <div class="headline long"></div>
                <div class="headline short"></div>

                <div class="description secondary-text"></div>
                <div class="description secondary-text"></div>
                <div class="description secondary-text"></div>
                <div class="description secondary-text"></div>
                <div class="description secondary-text"></div>
                <div class="description secondary-text short"></div>

                <div class="site secondary-text"></div>
            </div>
        </div>

        <span class="link-spanner"></span>
    </div>
</template>

<style scoped>
    .news-card {
        position: relative;

        min-width: 5rem;
        width: auto;
        max-width: 33.5rem;
        
        max-height: 175px;

        padding: 10px;

        background-color: var(--highlight-color);
        border-radius: 5px;

        cursor: pointer;
        transition: 0.2s ease-out;
    }

    .news-card:hover {
        box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
        -webkit-box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
        -moz-box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
        background-color: var(--highlight-hover-color);

        transition: 0.08s ease-out;
    }

    .link-spanner {
        position: absolute;

        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        z-index: 1;
    }

    .main-content {
        display: flex;
        max-height: 100%;
    }

    .image-wrapper {
        position: relative;
        overflow: hidden;

        border-radius: 5px;
    }

    .thumbnail {
        width: 210px;
        height: 155px;

        border-radius: 5px;

        background-color: var(--skeleton-accent-primary-color);
        opacity: var(--image-opacity);

        transition: 0.2s ease-out;
    }

    .thumbnail::before {
        content: '';
        display: block;
        position: absolute;

        left: -150px;
        top: 0;
        height: 100%;
        width: 150px;
        
        background: var(--skeleton-background);
        animation: load 3s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }

    .main-text-content {
        display: flex;
        flex-direction: column;
        flex: 1;

        margin: 0 0 0 10px;
        max-height: 155px;
    }

    .long {
        width: 100%;
    }

    .short {
        width: 100px;
    }

    .headline {
        height: 12px;
        margin: 0 0 10px 0;
        overflow: hidden;

        border-radius: 2px;
        background-color: var(--skeleton-accent-primary-color);

        transition: 0.2s ease-out;
    }

    .headline::before {
        content: '';
        display: block;
        position: relative;

        left: -150px;
        top: 0;
        height: 12px;
        width: 150px;

        background: var(--skeleton-background);
        animation: load 3s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }

    .secondary-text {
        height: 5px;
        margin: 5px 0 0 0;
        overflow: hidden;

        border-radius: 1px;
        background-color: var(--skeleton-accent-primary-color);

        transition: 0.2s ease-out;
    }

    .secondary-text::before {
        content: '';
        display: block;
        position: relative;

        left: -150px;
        top: 0;
        height: 5px;
        width: 150px;

        background: var(--skeleton-background);
        animation: load 3s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }

    .site {
        width: 80px;
        margin-top: auto;
    }

    @media only screen and (max-width: 500px) {
        .main-content {
            flex-direction: column;
        }

        .news-card {
            max-height: none;
        }

        .thumbnail {
            width: 100%;
        }

        .main-text-content {
            margin-left: 0;
            max-height: none;
            margin-top: 10px;
        }

        .description {
            max-height: none;
        }

        .site {
            margin-top: 10px;
        }
    }
</style>
