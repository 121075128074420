<template>
    <div class="news-cards">
        <news-card-skeleton class="card-container" />
        <news-card-skeleton class="card-container" />
        <news-card-skeleton class="card-container" />
    </div>
</template>

<script>
import NewsCardSkeleton from './NewsCardSkeleton.vue';

export default {
    name: "NewsSkeleton",
    components: {
        NewsCardSkeleton,
    },
}
</script>

<style scoped>
    .news-cards {
        display: flex;
        flex-direction: column;

        position: relative;

        min-width: 20em;
        width: auto;
        max-width: 33.5em;

        height: auto;

        margin: 0 auto;
    }

    .card-container {
        margin-bottom: 10px;
    }
</style>
