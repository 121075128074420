const global = {
    namespaced: true,
    state: {
        theme: "light",
        cookiesAccepted: false,
    },
    mutations: {
        setTheme(state, themeName) {
            const validThemes = ["light", "dark"];
            themeName = themeName.toLowerCase();
            
            if (validThemes.includes(themeName)) {
              state.theme = themeName;
            }
        },
        acceptCookies(state, cookiesAccepted) {
            state.cookiesAccepted = cookiesAccepted;
        }
    },
    actions: {
        /* A helper function that waits for a condition to be true, using async/await. This
        allows Suspense components to work when waiting for a condition to become true. */
        until(context, conditionFunction) {
            const poll = resolve => {
                if(conditionFunction()) resolve();
                else setTimeout(() => poll(resolve), 400);
            }

            return new Promise(poll);
        },
    }
}

export default global;
