<template>
    <nav>
        <ul>
            <li><Title /></li>
            <!-- <li><LoginButton /></li> -->
            <li><ThemeToggle /></li>
            <li><Searchbar /></li>
        </ul>
    </nav>
</template>

<script>
import Title from '../common/Title.vue';
import Searchbar from '../search/Searchbar.vue';
import ThemeToggle from '../common/ThemeToggle.vue';
// import LoginButton from '../common/LoginButton.vue';

export default {
    name: 'Navbar',
    components: {
        Title,
        Searchbar,
        ThemeToggle,
        // LoginButton,
    }
}
</script>

<style scoped>
    nav {
        margin: 0 -5px;
        padding: 0 5px;

        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 0;

        box-shadow: 0 6px 6px -6px var(--dark-box-shadow-color);
        background-color: var(--main-page-color);

        transition: 0.2s ease-out;

        z-index: 999; /* The navbar should always be on top, no matter what. Kinda hacky, but it works */
    }

    nav ul {
        margin: 0px 12px 0px 12px;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        align-items: center;

        transition: 0.2s ease-out;
    }

    nav li {
        position: relative;
        display: inline-flex;
        align-items: center;
    }

    nav ul li:nth-last-child(2) {
        margin: 0 20px 0 auto;
    }

    @media only screen and (min-width: 878px) {
        nav ul li:last-child {
            width: 540px;
        }
    }

    @media only screen and (max-width: 897px) {
        nav ul li:last-child {
            flex: 1;
        }

        nav ul li:first-child {
            margin-right: 20px;
        }
    }

    @media only screen and (max-width: 601px) {
        nav ul {
            margin-bottom: 12px
        }

        nav {
            position: relative;
        }
    }

    @media only screen and (max-width: 562px) {
        nav ul li:nth-last-child(2) {
            margin-right: 4px;
        }
    }

    @media only screen and (max-width: 360px) {
        nav ul li:first-child {
            margin-right: 10px;
        }

        nav ul li:nth-last-child(2) {
            margin-right: 4px;
        }
    }

    @media only screen and (max-height: 312px) {
        nav {
            position: static;
        }
    }

    /* nav ul li:nth-last-child(3) {
        margin: 0 auto 0 20px;
    }

    @media only screen and (max-width: 1059px) {
        nav ul li:last-child {
            flex: 1;
            min-width: 145px;
        }
    }

    @media only screen and (min-width: 878px) {
        nav ul li:last-child {
            width: 540px;
        }
    }

    @media only screen and (max-width: 663px) {
        nav ul {
            margin-bottom: 12px
        }

        nav {
            position: relative;
        }
    }

    @media only screen and (max-width: 613px) {
        nav ul li:nth-last-child(2) {
            margin-right: 4px;
        }
    }

    @media only screen and (max-width: 502px) {
        nav ul li:nth-last-child(2) {
            margin-right: 10px;
        }
    }

    @media only screen and (max-width: 334px) {
        nav ul li:nth-last-child(3) {
            margin-bottom: 10px;
        }

        nav ul li:nth-last-child(2) {
            margin-bottom: 10px;
        }
    }

    @media only screen and (max-width: 313px) {
        nav ul li:nth-last-child(3) {
            margin-left: 0;
        }

        nav ul li:nth-last-child(2) {
            margin-right: 0;
        }
    }

    @media only screen and (max-height: 312px) {
        nav {
            position: static;
        }
    } */
</style>
