<template>
    <div class="selected"></div>
</template>

<script>
export default {
    name: "OrderDropdownSkeleton",
}
</script>

<style scoped>
    .selected {
        max-width: 200px;
        width: 100%;
        height: 30px;

        padding-left: 0.5rem;

        display: flex;
        position: relative;
        overflow: hidden;
        outline: none;
        z-index: 99;

        cursor: pointer;
        user-select: none;

        background-color: var(--skeleton-accent-primary-color);

        border-radius: 6px; /* 5px */
        border: 1px solid var(--highlight-hover-color);

        transition: 0.2s ease-out;
    }

    .selected::before {
        content: '';
        display: block;
        position: relative;

        left: -150px;
        top: 0;
        height: 100%;
        width: 150px;

        background: var(--skeleton-background);
        animation: load 3s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }
</style>
