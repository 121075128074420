<template>
        <h1>Advanced Search</h1>
        <advanced-search-input />

        <div id="search-options">
            <div id="filters">
                <checkbox class="orbit-cb" :value="true" label="Only return satellites currently in orbit" storeID="orbitRequired"/>

                <h2 id="filters-title">Textual Filters</h2>
                <column-filter columnName="NORAD Catalog Number" placeholder="Add a NCN..." />
                <column-filter columnName="Name" placeholder="Add a name..." />
                <column-filter columnName="International Designator" placeholder="Add an ID..." />
                <column-filter columnName="Launch Site" placeholder="Add a launch site..." showInfo>
                    <p style="text-align: left; margin: 0;">Case sensitive.<br>Accepted values can be found <a href="https://celestrak.org/satcat/launchsites.php" target="_blank">here</a> (launch site codes).</p>
                </column-filter>
                
                <dropdown-column-filter
                    columnName="Status"
                    placeholder="Add a status..."
                    :options="[{title: 'Operational', value: '+'}, {title: 'Non-operational', value: '-'}, {title: 'Partially Operational', value: 'P'}, {title: 'Backup/Standby', value: 'B'}, {title: 'Spare', value: 'S'}, {title: 'Extended Mission', value: 'X'}, {title: 'Decayed', value: 'D'}, {title: 'Unknown', value: '?'}]"
                />
                <!-- <dropdown-column-filter
                    columnName="Class of Orbit"
                    placeholder="Add an orbit class..."
                    :options="[{title: 'LEO', value: 'LEO'}, {title: 'GEO', value: 'GEO'}, {title: 'MEO', value: 'MEO'}, {title: 'Elliptical', value: 'Elliptical'}, ]"
                />
                <dropdown-column-filter
                    columnName="Type of Orbit"
                    placeholder="Add an orbit type..."
                    :options="[{title: 'Polar', listingTitle: 'Polar', value: 'Polar'}, {title: 'Non-Polar Inclined', listingTitle: 'Non-polar Inclined', value: 'Non-Polar Inclined'}, {title: 'Deeply Highly Eccentric', listingTitle: 'Deeply highly eccentric', value: 'Deeply Highly Eccentric'}, {title: 'Sun-Synchronous', listingTitle: 'Sun-synchronous', value: 'Sun-Synchronous'}, {title: 'Molniya', listingTitle: 'Molniya', value: 'Molniya'}, {title: 'Equatorial', listingTitle: 'Equatorial', value: 'Equatorial'}, ]"
                />
                <dropdown-column-filter
                    columnName="Users"
                    placeholder="Add a user..."
                    :options="[{title: 'Government', value: 'Government'}, {title: 'Civil', value: 'Civil'}, {title: 'Military', value: 'Military'}, {title: 'Commercial', value: 'Commercial'}, ]"
                /> -->

                <h2 id="filters-title">Date Filters</h2>
                <date-column-filter columnName="Launch Date"/>
                <date-column-filter columnName="Decay Date"/>

                <h2 id="filters-title">Numeric Filters</h2>
                <numeric-column-filter columnName="Apogee" units="km" />
                <numeric-column-filter columnName="Perigee" units="km" />
                <numeric-column-filter columnName="Period" units="minutes" />
                <numeric-column-filter columnName="Inclination" units="degrees" />
                <numeric-column-filter columnName="Mass" units="kg" />
            </div>

            <div id="buttons">
                <reset-button style="margin-right: auto" />
                <search-button />
            </div>
        </div>
</template>

<script>
import AdvancedSearchInput from '../components/search/AdvancedSearchInput.vue';
import ColumnFilter from '../components/search/ColumnFilter.vue';
import DropdownColumnFilter from '../components/search/DropdownColumnFilter.vue';
import DateColumnFilter from '../components/search/DateColumnFilter.vue';
import NumericColumnFilter from '../components/search/NumericColumnFilter.vue';
import SearchButton from '../components/search/SearchButton.vue';
import ResetButton from '../components/search/ResetButton.vue';
import Checkbox from '../components/common/Checkbox.vue';

export default {
    name: "AdvancedSearch",
    components: {
        AdvancedSearchInput,
        ColumnFilter,
        DropdownColumnFilter,
        DateColumnFilter,
        NumericColumnFilter,
        SearchButton,
        ResetButton,
        Checkbox,
    },
    setup() {
        
    },
}
</script>

<style scoped>
    .orbit-cb {
        margin: 25px auto 10px auto;
    }

    #search-options {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    #filters-title {
        margin: 25px 0 10px 0;
    }

    #filters {
        flex-shrink: 0;
    }

    #buttons {
        max-width: 540px;
        min-width: 230px;
        width: 100%;

        margin: 0 auto 0 auto;
        padding-bottom: 120px;

        display: flex;
        justify-content: right;
        align-items: flex-end;
        flex-grow: 1;
    }
</style>