<template>
    <div id="about">
        <h1>About Us</h1>

        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec semper libero orci, eu imperdiet massa placerat ut. leo et
            <br>Sed id laoreet libero, vulputate volutpat elit. Phasellus at risus ac nunc pulvinar aliquam vitae sit amet nisi. Fusce feugiat,
            <br>rhoncus imperdiet, sem lectus blandit augue, et posuere nisl tortor eget nibh. Ut non ex maximus, euismod nisi sit amet,
            <br>elementum ipsum. Ut et felis orci. Vivamus in bibendum ex, et rutrum enim. Ut scelerisque ex et volutpat fringilla. Proin
            <br>vestibulum arcu nec neque posuere, non rhoncus lacus tristique. Quisque molestie nisl ac mi egestas pharetra.
        </p>

        <div class="link-list">
            <h3 id="learn-more-header">Learn more</h3>

            <QuickLink displayText="Who We Are" routerLink="/about/us" :showLink="false" />
            <QuickLink displayText="Services" routerLink="/about/products" :showLink="false" />
            <QuickLink displayText="API" routerLink="/about/api" :showLink="false" />
        </div>
    </div>
</template>

<script>
import QuickLink from '../components/common/QuickLink.vue';

export default {
    name: "About",
    components: {
        QuickLink,
    }
}
</script>

<style scoped>
    p {
        color: var(--secondary-text-color);
    }

    #learn-more-header {
        margin: 0;
        margin: 10px 0 10px 0;
    }

    .link-list {
        margin: 0 auto;
    }
</style>