<template>
    <div class="news-cards">
        <news-card class="card-container" v-for="newsArticle in newsArticles" :key="newsArticle" :article="newsArticle"/>
    </div>
</template>

<script>
import { reactive } from 'vue';

import NewsCard from './NewsCard.vue';

export default {
    name: "News",
    components: {
        NewsCard,
    },
    async setup() {
        const newsArticles = reactive([]);

        async function getNewsFeed() {
            // Get 3 satellite news articles from the Space Flight News API (https://spaceflightnewsapi.net/)
             const response = await fetch(
                "https://api.spaceflightnewsapi.net/v4/articles/?limit=3&title_contains=satellite",
                { method: "GET", cookie: "SameSite=None;Secure" }
            );
            const articles = await response.json();

            for (const article of articles["results"]) {
                // The article will have the key "code" if it can't be found (403, 404, unexpected behaviour)
                if (!("statusCode" in article)) {
                    newsArticles.push(article);
                }
            }
        }

        await getNewsFeed();

        return {
            newsArticles,
        }
    },
}
</script>

<style scoped>
    .news-cards {
        display: flex;
        flex-direction: column;

        position: relative;

        min-width: 20em;
        width: auto;
        max-width: 33.5em;

        height: auto;

        margin: 0 auto;
    }

    .card-container {
        margin-bottom: 10px;
    }
</style>
