<template>
    <div class="date-select" @blur="open = false" tabindex="0">
        <div class="selected" :class="{ open: open }" @click="open = !open">
            {{ selected }}
        </div>

        <div class="items" :class="{ selectHide: !open }">
            <div
                v-for="(option, i) of options"
                :key="i"
                @click="
                    selected = option;
                    open = false;
                    input(option);
                "
                :class="{ 'selected-item': option === selected }"
            >
                <p>{{ option }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import { ref } from 'vue';

export default {
    name: "DateDropdown",
    props: {
        options :{
            type: Array,
            required: true,
        },
        default: {
            type: String,
            required: false,
            default: null,
        },
        source: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const store = useStore();

        const selected = props.default
                        ? props.default
                        : props.options.length > 0
                        ? props.options[0]
                        : null
        const open = ref(false);
        
        function input(option) {
            const sourceData = store.state.satellite_data.satData[props.source];
            
            for (const data of sourceData) {
                if (data["Update Timestamp"] === option) {
                    store.commit("satellite_data/setSelectedData", { source: props.source, dataObject: data });
                    return;
                }
            }
        }

        input(props.default);
        
        return {
            selected,
            open,
            input,
        }
    },
}
</script>

<style scoped>
    .date-select {
        position: relative;

        min-width: 190px;
        width: 190px;
        max-width: 190px;
        height: 30px;

        text-align: left;
        outline: none;

        font-size: 13.6px;
        line-height: 47px;
    }

    .selected {
        display: flex;
        align-items: center;
        height: 30px;
        
        padding-left: 0.5rem;

        border-radius: 6px;
        border: 1px solid var(--highlight-hover-color);

        color: var(--main-text-color);
        background-color: var(--highlight-color);

        cursor: pointer;
        user-select: none;

        transition: 0.2s ease-out;
    }

    .selected.open {
        border: 1px solid var(--highlight-hover-color);
        border-radius: 6px 6px 0px 0px;
    }

    .selected:after {
        position: absolute;

        width: 0;
        height: 0;

        top: 13px;
        right: 1em;
        
        content: "";

        border: 5px solid transparent;
        border-color: var(--dropdown-arrow-color) transparent transparent transparent;

        transition: 0.2s ease-out;
    }

    .items {
        position: absolute;
        overflow: hidden;

        border-radius: 0px 0px 6px 6px;
        border-right: 1px solid var(--highlight-hover-color);
        border-left: 1px solid var(--highlight-hover-color);
        border-bottom: 1px solid var(--highlight-hover-color);
        
        color: var(--main-text-color);
        background-color: var(--highlight-color);
        
        left: 0;
        right: 0;
        z-index: 1;

        animation: fadeIn 0.2s ease-out;

        transition: 0.2s ease-out;
    }

    .items div {
        display: flex;
        align-items: center;

        height: 30px;
        padding-left: 0.5rem;

        color: var(--main-text-color);

        cursor: pointer;
        user-select: none;

        transition: 0.2s ease-out;
    }

    .items div:hover {
        background-color: var(--highlight-hover-color);
    }

    .selected-item {
        background-color: var(--highlight-hover-color);
    }

    .selected-item p {
        color: rgb(117, 117, 117);
    }

    .selectHide {
        display: none;
    }

    @keyframes fadeIn {
        0% { 
            opacity: 0;
        }

        100% {
            opacity: 1;
            }
    }
</style>
