<template>
    <div
    class="checkbox"
    :class="{ 'checked': isChecked }"
    tabindex="0"
    @click="handleClick()"
  >
    <div class="checkbox-icon" :class="{ 'icon-checked': isChecked }">
        <img v-if="isChecked" class="check-mark" src="../../assets/check_mark.png" width="11" height="11">
    </div>

    <p class="label">{{ label }}</p>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "Checkbox",
    props: {
        value: {
            type: Boolean,
            required: true,
            default: false,
        },
        label: {
            type: String,
            required: false,
            default: "",
        },
        storeID: {
            type: String,
            required: true,
        },
        // checked: {
        //     type: Boolean,
        //     required: false,
        //     default: false,
        // },
        // id: {
        //     type: String,
        //     required: true,
        //     default: "cb",
        // },
        // disabled: {
        //     type: Boolean,
        //     required: false,
        //     default: false,
        // }
    },
    setup(props) {
        const store = useStore();
        const isChecked = ref(store.state.advanced_search[props.storeID] === "true");

        function handleClick() {
            isChecked.value = !isChecked.value;
            store.commit("advanced_search/setOption", { option: props.storeID, newValue: isChecked.value.toString() })
        }

        return {
            isChecked,
            handleClick,
        }
    },
}
</script>

<style scoped>
    .checkbox {
        display: flex;
        align-items: center;

        width: fit-content;
        
        cursor: pointer;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;

        transition: 0.08s ease-out;
    }

    /* .checkbox:hover .checkbox-icon {
        filter: invert(10%);
    } */

    .checkbox-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 15px;
        height: 15px;
        margin: 3px 5px 3px 0;

        background-color: var(--highlight-color);
        border: 1px solid var(--secondary-border-color);
        border-radius: 3px;
     
        transition: 0.08s ease-out;
    }

    .icon-checked {
        background-color: var(--tab-active-color) !important;
        border-color: var(--tab-active-color) !important;
    }

    .check-mark {
        filter: invert(100%);
        -webkit-filter: invert(100%);

        transition: 0.08s ease-out;
        image-rendering: -webkit-optimize-contrast;
    }

    .label {
        font-weight: bold;
        margin: 0;
    }
</style>
