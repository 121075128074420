<template>
    <div class="dropdown-filter" @blur="close()" :tabindex="index">
        <div class="selected" :class="{ open: open, 'placeholder': !selected['value'] }" @click="open = !open">
            <p>{{ selected["title"] }}</p>
        </div>

        <div class="items" :class="{ selectHide: !open }">
            <div
                v-for="(option, i) of options"
                :key="i"
                @click="
                    selected['value'] = option['value'];
                    selected['title'] = option['title'];
                    open = false;
                    input(option, true);
                "
                :class="{ 'selected-item': option['value'] === selected['value'] }"
            >
                <p>{{ option["title"] }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, watch } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "TextualColumnDropdown",
    props: {
        options :{
            type: Array,
            required: true,
        },
        default: {
            type: Object,
            required: false,
            default: null,
        },
        columnName: {
            type: String,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        }
    },
    setup(props, { emit }) {
        const store = useStore();

        const selected = reactive(props.default
                        ? { ...props.default }
                        : props.options.length > 0
                        ? { ...props.options[0] }
                        : { value: "", title: "No value" });
        const open = ref(false);

        function close() {
            open.value = false;
        }
        
        function input(option, emitChange) {
            selected["value"] = option["value"];
            selected["title"] = option["title"];

            if (emitChange) {
                emit("valueChanged", { value: selected["value"], index: props.index });
            } 
        }

        function updateValue() {
            let selectedValue;

            try {
                selectedValue = store.state.advanced_search[props.columnName][props.index];
            } catch {
                return;
            }

            if (selectedValue === selected["value"]) {
                return;
            } else if (!selectedValue) {
                input(props.default, false);
            } else {
                let selected;

                props.options.forEach((option) => {
                    if (option["value"] === selectedValue) {
                        selected = option;
                    }
                });

                input(selected, false);
            }
        }

        updateValue();

        watch(() => store.state.advanced_search[props.columnName], () => {
            updateValue();
        },
        { deep: true });
        
        return {
            selected,
            open,
            input,
            close,
        }
    },
}
</script>

<style scoped>
    .dropdown-filter {
        position: relative;

        text-align: left;
        outline: none;

        font-size: 13.25px;
        line-height: 47px;
    }

    .selected {
        display: flex;
        align-items: center;
        height: 30px;
        
        padding-left: 0.5rem;

        border-radius: 6px; /* 5px */
        border: 1px solid var(--highlight-hover-color);

        color: var(--main-text-color);
        background-color: var(--highlight-color);

        cursor: pointer;
        user-select: none;

        transition: 0.2s ease-out;
    }

    .selected.open {
        border: 1px solid var(--highlight-hover-color);
        border-radius: 6px 6px 0px 0px;
    }

    .selected:after {
        position: absolute;

        width: 0;
        height: 0;

        top: 13px;
        right: 1em;
        
        content: "";

        border: 5px solid transparent;
        border-color: var(--dropdown-arrow-color) transparent transparent transparent;

        transition: 0.2s ease-out;
    }

    .items {
        position: absolute;
        overflow: hidden;

        border-radius: 0px 0px 6px 6px;
        border-right: 1px solid var(--highlight-hover-color);
        border-left: 1px solid var(--highlight-hover-color);
        border-bottom: 1px solid var(--highlight-hover-color);

        box-shadow: 0px 0px 10px 1px var(--light-box-shadow-color);
        
        color: var(--main-text-color);
        background-color: var(--highlight-color);
        
        left: 0;
        right: 0;
        z-index: 1;

        animation: fadeIn 0.2s ease-out;
        transition: 0.2s ease-out;
    }

    .items div {
        display: flex;
        align-items: center;

        height: 30px;
        padding-left: 0.5rem;

        color: var(--main-text-color);

        cursor: pointer;
        user-select: none;

        transition: 0.2s ease-out;
    }

    .items div:hover {
        background-color: var(--highlight-hover-color);
    }

    .selected-item {
        background-color: var(--highlight-hover-color);
    }

    .selected-item p {
        color: rgb(117, 117, 117);
    }

    .selectHide {
        display: none;
    }

    .placeholder {
        color: rgb(117, 117, 117);
    }

    @keyframes fadeIn {
        0% { 
            opacity: 0;
        }

        100% {
            opacity: 1;
            }
    }
</style>
