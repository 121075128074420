<template>
    <div class="satellite-data-table">
        <div class="header">
            <h4 class="table-title"  v-if="!titleLink || titleLink === '#'">
                {{ title }}
            </h4>

            <h4 class="table-title" v-else>
                <a
                    class="header-link"
                    :href="titleLink"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {{ title }}
                </a>
            </h4>
        </div>

        <div class="date-select-container" v-if="showDateSelect">
            <p class="date-select-label">Showing data for</p>
            <div class="date-select-skeleton"></div>
        </div>

        <table-skeleton :row-count="rowCount"/>
    </div>
</template>

<script>
import TableSkeleton from './TableSkeleton.vue';

export default {
    name: "SatelliteDataTableSkeleton",
    props: {
        title: {
            type: String,
            required: false,
        },
        titleLink: {
            type: String,
            required: false,
        },
        showDateSelect: {
            type: Boolean,
            required: false,
        },
        rowCount: {
            type: Number,
            required: true,
        },
    },
    components: {
        TableSkeleton,
    }
}
</script>

<style scoped>
    .satellite-data-table {
        margin: 0 auto 0 auto;
        max-width: 750px;
        padding: 0;
    }

    .satellite-data-table h4 {
        margin: 0 auto;
        transition: 0.2s ease-out;
    }

    .table-title {
        margin: auto;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        color: var(--main-text-color);

        background-color: inherit;
    }

    .header-link {
        text-decoration: none;
        color: var(--main-text-color);

        transition: 0.2s ease-out;
    }

    .header-link:hover {
        text-decoration: underline;
    }

    .date-select-container {
        display: flex;
        
        justify-content: center;
        align-items: center;

        width: 100%;
        margin: 10px 0;
    }

    .date-select-label {
        margin-right: 10px;

        color: var(--secondary-text-color);
        font-size: 0.85rem;
    }

    .date-select-skeleton {
        min-width: 190px;
        width: 190px;
        max-width: 190px;
        height: 30px;

        position: relative;
        overflow: hidden;

        border-radius: 5px;
        background-color: var(--skeleton-accent-primary-color);

        transition: 0.2s ease-out;
    }

    .date-select-skeleton::before {
        content: '';
        display: block;
        position: relative;

        left: -150px;
        top: 0;
        height: 100%;
        width: 150px;

        background: var(--skeleton-background);
        animation: load 3s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }

    @media only screen and (max-width: 360px) {
        .date-select-container {
            flex-direction: column;
        }

        .date-select-label {
            margin: 0 0 5px 0;
        }
    }
</style>
