const home = {
    namespaced: true, 
    state: {
        recentSearches: [],
    },
    mutations: {
        addRecent(state, satellite) {
            if (state.recentSearches.some(sat => sat.ncn === satellite.ncn)) {
              // Remove the duplicate satellite
              state.recentSearches = state.recentSearches.filter(function(sat) {
                return sat.ncn != satellite.ncn
              });
            } else if (state.recentSearches.length >= 3) {
              // Only store the three most recent searches
              state.recentSearches.pop();
            }
      
            state.recentSearches.unshift(satellite);
          },
          
          clearSearchHistory(state) {
            state.recentSearches = [];
          },
    },
};

export default home;
