<template>
    <div class="result-card">
        <div class="result-card-header">
        <h3 class="result-card-title">{{ result["Name"] }}
            <router-link :to="'/satellite/' + result['NORAD Catalog Number']" event="" @click="cache_data">
                <span class="link-spanner"></span> <!-- Makes the whole div a clickable link -->
            </router-link>
        </h3>
        <h3 class="lifetime">{{ lifetime }}</h3>
        </div>

        <p class="result-card-content">{{ result["NORAD Catalog Number"] }} {{ id }}</p>
        <p class="result-card-content">{{ owner }}</p>
        <p class="result-card-content description line-clamp" :class="{'italic-description': description == 'No description available'}">{{ description }}</p>
    </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
    name: "SearchResult",
    props: {
        result: {
            type: Object,
            required: true,
        }
    },
    setup(props) {
        const store = useStore();
        const router = useRouter();

        const id = computed(() => {
            return props.result["International Designator"] ? "/ " + props.result["International Designator"] : "";
        });

        const owner = computed(() => {
            return props.result["Operator/Owner"] ? "Operated/owned by " + props.result["Operator/Owner"] : "Unknown operator/owner";
        });

        const lifetime = computed(() => {
            const launchDate = props.result["Launch Date"] ? props.result["Launch Date"] : "unknown";
            const decayDate = props.result["Decay Date"] ? props.result["Decay Date"] : "present";

            return launchDate + " - " + decayDate ;
        });

        const description = computed(() => {
            // Return only the first paragraph for brevity
            const desc = props.result["Description"];
            const valid_desc = desc && desc.trim().length !== 0;

            return valid_desc ? desc.split(/\r?\n/)[0] : "No description available";
        });

        function cache_data() {
            store.commit(
                "satellite_data/setSelectedData",
                { source: "name_cache", dataObject: { ncn: props.result["NORAD Catalog Number"], name: props.result["Name"] } }
            );

            const desc = props.result["Description"];
            const valid_desc = desc && desc.trim().length !== 0;

            store.commit(
                "satellite_data/setSelectedData",
                { source: "description_cache", dataObject: { ncn: props.result["NORAD Catalog Number"], description: valid_desc ? desc : null } }
            );

            // This is done here to ensure the the data cache is done *before* the page is redirected
            router.push('/satellite/' + props.result['NORAD Catalog Number'])
        }

        return {
            id,
            owner,
            lifetime,
            description,
            cache_data,
        }
    },
}
</script>

<style scoped>
    .result-card {
        min-width: 230px;
        max-width: 540px;
        width: 100%;
        max-height: 12em;

        margin-bottom: 10px;
        padding: 0 15px 0 15px;

        position: relative;

        display: flex;
        flex-direction: column;
        align-items: left;

        background-color: var(--highlight-color);
        border-radius: 5px;

        cursor: pointer;
        transition: 0.2s ease-out;
    }

    .result-card:hover {
        box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
        -webkit-box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
       -moz-box-shadow: 0px 0px 10px 1px var(--medium-box-shadow-color);
        background-color: var(--highlight-hover-color);
    
        transition: 0.08s ease-out;
    }

    .result-card-header {
        margin: 15px 0 2px 0;

        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .result-card-title {
        margin: 0;

        font-weight: bold;
        text-align: left;

        overflow: hidden;
        text-overflow: ellipsis;
    }

    .lifetime {
        margin: 0 0 0 5px;

        font-size: 1rem;
        text-align: left;
        font-style: italic;

        color: var(--secondary-text-color);
    }

    .result-card-content {
        margin: 0;

        font-size: 0.8rem;
        text-align: left;

        overflow: hidden;
        text-overflow: ellipsis; 

        color: var(--secondary-text-color);
    }

    .description {
        margin: 5px 0 15px 0;
    }

    .line-clamp {
      position: relative;
      max-height: 3.6em; /* 3 lines */
    }

    .line-clamp:after {
        content: "";
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 70%;
        height: 1.2em;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
    }

    /* Desired styling - the description is cut off by ellipses */
    @supports (-webkit-line-clamp: 3) {
        .line-clamp {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;  
        }

        .line-clamp:after {
            display: none;
        }
    }

    .italic-description {
        font-style: italic;
    }

    .link-spanner {
        position: absolute;
        
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        z-index: 1;
    }
</style>