<template>
    <div id="sources">
        <h1>Sources</h1>
        <p id="source-description">This website wouldn't be possible without the following websites, where we collect data from...</p>
        
        <div class="source-acknowledgement">
            <h2 class="source-title">CelesTrak</h2>
            <p class="source-body">
                CelesTrak is a company that specialises in satellites, and offer a variety of services. This website uses their <a href="https://celestrak.com/satcat/search.php" target="_blank">satellite catatalog</a>.
            </p>

             <p class="external-link">You can find CelesTrak at <a href="https://celestrak.com/" target="_blank">celestrak.com</a></p>
        </div>

        <div class="source-acknowledgement">
            <h2 class="source-title">Jonathan's Space Report (Planet4589)</h2>
            <p class="source-body">
                Planet4589 is a website maintained by Jonathan C. McDowell, and a large part of it is a satellite catalog. This website <br> 
                uses the high-quality data that Jonathan provides in his catalog. He also distributes a newsletter, <a href="https://www.planet4589.org/space/jsr/jsr.html" target="_blank">Jonathan's Space Report</a>, <br>
                which details all space launches.
            </p>

            <p class="external-link">You can find Planet4589 at <a href="https://www.planet4589.org/" target="_blank">planet4589.org</a></p>
            
        </div>

        <div class="source-acknowledgement">
            <h2 class="source-title">The Union of Concerned Scientists (UCS)</h2>
            <p class="source-body">
                The Union of Concerned Scientists are a nonprofit organisation, founded by MIT students and scientists, that advocates for <br>
                the use of "rigorous, independent science to solve our planet's most pressing problems". They have a <a href="https://www.ucsusa.org/resources/satellite-database" target="_blank">satellite database</a> <br>
                constructed from various sources, that this website uses.
            </p>

            <p class="external-link">You can find The Union of Concerned Scientists at <a href="https://www.ucsusa.org/" target="_blank">ucsusa.org</a></p>
        </div>

        <div class="source-acknowledgement">
            <h2 class="source-title">NASA Space Science Data Coordinated Archive (NSSDCA)</h2>
            <p class="source-body">
            The NASA Space Science Data Coordinated Archive (NSSDCA) is "the permanent archive for NASA space science mission data". <br>
            It contains data about a large number of satellites, in addition to article-like write-ups. This website uses the write-ups <br>
            to provide context to the satellite data that is displayed.
        </p>

        <p class="external-link">You can find the NSSDCA at <a href="https://nssdc.gsfc.nasa.gov/" target="_blank">nssdc.gsfc.nasa.gov</a></p>
        </div>
    </div>
</template>

<style scoped>
    #source-description {
        color: var(--secondary-text-color);
    }

    .source-acknowledgement {
        padding: 0;
        margin-bottom: 50px;
    }

    .source-title {
        padding: 0;
        margin: 0;
    }

    .source-body {
        padding: 0;
        margin: 5px;
    }
</style>