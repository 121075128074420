<template>
    <div>
        <h2>Log in</h2>
        <p class="signup-prompt">Don't have an account? <router-link to="/sign-up"><a class="signup-link">Sign up</a></router-link>.</p>
    </div>
</template>

<script>
export default {
    name: "Login",
    setup() {
        
    },
}
</script>

<style scoped>
    .signup-prompt {
        font-style: italic;
    }

    .signup-link {
        color: var(--main-text-color);
    }
</style>
